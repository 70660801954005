@import '../../common.scss';

.hp-appswitcher {
  width: 100%;
  height: 100%;
  display: contents;

  .hp-cookies {
    margin-left: responsiveSizeToV2(20);
    margin-bottom: responsiveSizeToV2(20);
  }

  .hp-mention {
    display: flex;
    position: absolute;    
    flex-direction: column;
    align-items: flex-end;
    right: responsiveSizeToV2(13);
    bottom: responsiveSizeToV2(9);
    svg {
      width: responsiveSizeToV2(58);
      height: responsiveSizeToV2(48);
    }
    p {
      margin: 0;
      color: #C3C3C3;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(19);
      a {
        color: #C3C3C3;
      }
    }
  }

  .hp-old-version {
    position: absolute;
    margin-top: responsiveSizeToV2(700);
    color: white;
    a {
      color: white;
      font-family: 'Avenir';
      text-decoration: none;
      font-size: responsiveSizeToV2(20);
      span {
        text-decoration: underline;
      }
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .hp-hoopowLogo {
      cursor: pointer;
      width: responsiveSizeToV2(615);
      height: responsiveSizeToV2(147);
      margin-bottom: responsiveSizeToV2(41);
    }

    .hp-links {
      display: flex;
      justify-content: center;

      a, button {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;
        border: none;
        &:disabled {
          opacity: 0.4;
        }

        svg {
          cursor: pointer;
          width: responsiveSizeToV2(169);
          height: responsiveSizeToV2(172);
        }

        h2 {
          margin: 0;
          color: #DDDDDD;
          font-family: 'Baloo Bhaijaan 2';
          font-size: responsiveSizeToV2(24);
          line-height: responsiveSizeToV2(41);
          margin-top: responsiveSizeToV2(10);
        }

        p {
          margin: 0;
          color: #C3C3C3;
          font-weight: 700;
          text-align: center;
          font-family: 'Avenir';
          font-size: responsiveSizeToV2(18);
          line-height: responsiveSizeToV2(19);
        }

        &:first-child {
          margin-right: responsiveSizeToV2(51);
        }

        label {
          cursor: pointer;
          display: flex;
          color: black;
          font-weight: 900;
          align-items: center;
          font-family: 'Avenir';
          text-decoration: none;
          justify-content: center;
          background-color: white;
          text-transform: uppercase;
          width: responsiveSizeToV2(126);
          height: responsiveSizeToV2(30);
          font-size: responsiveSizeToV2(16);
          margin-top: responsiveSizeToV2(20);
          border-radius: responsiveSizeToV2(56);
          &.soon {
            width: responsiveSizeToV2(200);
          }
        }
      }
    }
  }
}