@function responsiveEmbeddedSize($sizeInPixel) {
  @return calc(#{$sizeInPixel} / 740 * var(--mainContentHeight));
}

body.embeddedBody .ks-embedded.hp-v3 {
  &.hp-explanation {
    .ks-content {
      align-items: flex-start;
    }
    .hp-explanation-template {
      font-family: 'Avenir';
      .hp-explanation-template-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: responsiveEmbeddedSize(22);
        margin-top: responsiveEmbeddedSize(15);
        svg {
          width: responsiveEmbeddedSize(49);
          height: responsiveEmbeddedSize(49);
          margin-right: responsiveEmbeddedSize(10);
        }
      }
      .hp-explanation-template-text {
        font-size: responsiveEmbeddedSize(23);
        margin-top: responsiveEmbeddedSize(20);
        margin-left: responsiveEmbeddedSize(40);
        margin-right: responsiveEmbeddedSize(40);
      }
      .hp-explanation-template-bottomImage {
        margin-top: responsiveEmbeddedSize(5);
        svg {
          width: responsiveEmbeddedSize(1020);
          height: responsiveEmbeddedSize(370);
        }
      }
    }
    &.hp-what {
      .hp-explanation-template-bottomImage {
        margin-top: responsiveEmbeddedSize(17);
        svg {
          width: responsiveEmbeddedSize(1020);
          height: responsiveEmbeddedSize(390);
        }
      }
    }
    &.hp-supportus {
      .hp-explanation-template-bottomImage {
        margin-top: responsiveEmbeddedSize(50);
        svg {
          width: responsiveEmbeddedSize(927);
          height: responsiveEmbeddedSize(553);
        }
      }
    }
  }
}