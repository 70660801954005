@import '../../common.scss';

.hp-v3 {
  .ks-avatar-selector {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;

    &__title {
      color: white;
      font-weight: 700;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(21);
      margin-top: responsiveSizeToV2(34);
      margin-bottom: responsiveSizeToV2(12);
    }

    &__gender {
      margin-bottom: responsiveSizeToV2(17);

      svg {
        cursor: pointer;
        width: responsiveSizeToV2(64);
        height: responsiveSizeToV2(64);

        &:not(:last-child) {
          margin-right: responsiveSizeToV2(16);
        }

        &.selected {
          g rect {
            fill: white;
            stroke: white;
          }

          cursor: default;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      width: responsiveSizeToV2(328);
      height: responsiveSizeToV2(493);
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .loader {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: responsiveSizeToV2(75);
        height: responsiveSizeToV2(75);
        margin-bottom: responsiveSizeToV2(17);

        &:not(:nth-child(4n)) {
          margin-right: responsiveSizeToV2(9);
        }

        svg,
        img {
          width: responsiveSizeToV2(75);
          height: responsiveSizeToV2(75);
          border-radius: responsiveSizeToV2(75);
          opacity: 0.3;
          cursor: pointer;
        }

        &.selected {

          svg,
          img {
            opacity: 1;
            cursor: default;
          }

          &:hover {
            z-index: 10;

            img {
              top: responsiveSizeToV2(260);
              left: responsiveSizeToV2(142);
              position: absolute;
              transform: scale(3);
            }
          }
        }
      }
    }

    .choose-btn {
      display: none;
    }
  }

  &.mobile-only {
    .ks-avatar-selector {
      width: 100%;
      height: 100%;

      &__title {
        display: none
      }

      &__gender {
        width: 100%;
        margin-top: responsiveSizeToV2(28);
        margin-bottom: responsiveSizeToV2(52);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: responsiveSizeToV2(186);
          height: responsiveSizeToV2(186);

          &:not(:last-child) {
            margin-right: responsiveSizeToV2(46);
          }
        }
      }

      &__list {
        width: 100%;
        height: responsiveSizeToV2(912);
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-auto-columns: 1fr;
        overflow-x: scroll;
        overflow-y: hidden;

        &-flex {
          display: flex;
        }

        &-item {
          width: responsiveSizeToV2(280);
          height: responsiveSizeToV2(287);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 9999px;

          svg,
          img {
            width: 100%;
            height: 100%;

            &:hover {
              position: unset;
              transform: none;
            }
          }

          &.selected {
            border-width: responsiveSizeToV2(6);
            border-color: #fff;

            svg,
            img {
              border-width: responsiveSizeToV2(6);
              border-color: #fff;
            }
          }

          &:not(:nth-child(4n)) {
            margin: unset;
          }
        }
      }

      .choose-btn {
        margin-top: responsiveSizeToV2(27);
        background: white;
        border-radius: responsiveSizeToV2(18);
        border-width: responsiveSizeToV2(6);
        color: black;
        width: responsiveSizeToV2(516);
        height: responsiveSizeToV2(120);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Avenir";
        font-size: responsiveSizeToV2(47);
        font-weight: 950;
      }
    }
  }
}