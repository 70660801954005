@import '../../common.scss';

.ks-embedded {
  .hp-pause-popup {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    svg {
      width: 100%;
      height: 100%;
    }
    .authentication-btn {
      top: 57%;
      left: 10%;
      display: flex;
      flex-direction: column;
      position: absolute;
      .mainBtn {
        font-weight: 700;
        font-family: 'Avenir';
        font-size: responsiveSizeToV2(26);
        width: responsiveSizeToV2(260);
        height: responsiveSizeToV2(70);
        border-radius: responsiveSizeToV2(11);
        margin-top: responsiveSizeToV2(10);
        &.ks-boxShadow {
          color: #000;
          background-color: #FFFFFF;
          margin-top: responsiveSizeToV2(20);
        }
      }
    }
  }
}