@import '../../common.scss';

.hp-v3 {
  .ks-content {
    .hp-successfulPayment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Avenir';
      flex-direction: column;

      &.mobile-only {
        svg {
          width: responsiveSizeToV2(550);
          height: responsiveSizeToV2(550);
        }

        .hp-successfulPayment__info {
          color: #51AABC;
          font-size: responsiveSizeToV2(53);
          font-weight: 950;
          margin-top: responsiveSizeToV2(-27);
          margin-bottom: responsiveSizeToV2(13);
        }

        .hp-successfulPayment__btn {
          border: none;
          color: white;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          background-color: #352a53;
          height: responsiveSizeToV2(120);
          width: responsiveSizeToV2(880);
          font-size: responsiveSizeToV2(47);
          font-weight: 950;
          border-radius: responsiveSizeToV2(18);
          margin-bottom: responsiveSizeToV2(52);
        }

        .hp-successfulPayment__thanks {
          display: none;
        }

        .stripe {
          width: 100%;
          display: flex;
          color: #484848;
          align-items: center;
          justify-content: center;
          font-size: responsiveSizeToV2(44);
          margin-bottom: responsiveSizeToV2(22);

          svg {
            height: responsiveSizeToV2(60.14);
            width: auto;
          }
        }
      }

      &.desktop-only {
        svg {
          margin-top: responsiveSizeToV2(16);
          width: responsiveSizeToV2(290);
          height: responsiveSizeToV2(290);
        }

        .hp-successfulPayment__info {
          color: #51AABC;
          font-size: responsiveSizeToV2(34);
          margin-top: responsiveSizeToV2(34);
          font-weight: 500;
          margin-bottom: responsiveSizeToV2(14);
        }

        .hp-successfulPayment__btn {
          border: none;
          color: white;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          background-color: #51AABC;
          height: responsiveSizeToV2(60);
          width: responsiveSizeToV2(396);
          font-size: responsiveSizeToV2(25);
          border-radius: responsiveSizeToV2(10);
          margin-bottom: responsiveSizeToV2(13);
        }

        .hp-successfulPayment__thanks {
          color: black;
          font-size: responsiveSizeToV2(23);
        }

        .stripe {
          display: none;
        }
      }
    }
  }
}