@import "../../common.scss";

@function responsiveSizeOnlyWidth($sizeInPixel) {
  $unit: vw;
  $result: $sizeInPixel / 1920 * 1 * 100;
  @return $result + $unit;
}

@function responsiveSizeOnlyWidthMobile($sizeInPixel) {
  $unit: vw;
  $result: $sizeInPixel / 414 * 1 * 100;
  @return $result + $unit;
}

body.hp-body-homepage-notconnected {
  overflow-y: auto;
  position: relative;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &.nooverflow {
    overflow-y: hidden;
  }
  #app-platform {
    display: block !important;
  }
}

#noop {
  height: 0;
  display: none;
}

.hp-hoopow-homepage-eid-gift {
  &-mobile {
    display: none;
    @include media("<481px") {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      z-index: 3;
      width: responsiveSizeOnlyWidthMobile(414);
      height: responsiveSizeOnlyWidthMobile(84);
    }
  }
}

.hp-homepage-notconnected {
  width: responsiveSizeOnlyWidth(1920);
  height: responsiveSizeOnlyWidth(3508);

  .hp-hoopow-homepage-eid-gift {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: responsiveSizeOnlyWidth(709);
    height: responsiveSizeOnlyWidth(145);
    @include media("<481px") {
      display: none;
    }
    &-mobile {
      display: none;
      @include media("<481px") {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: responsiveSizeOnlyWidthMobile(414);
        height: responsiveSizeOnlyWidthMobile(84);
      }
    }
  }
  @supports (-webkit-touch-callout: none) {
    @media only screen 
    and (min-device-width: 728px) {
      height: calc(182.7vw + 5vw);
    }
    @media only screen 
    and (max-device-width: 728px) {
      height: calc(182.7vw + 13vw);
    }
  }

  .hp-homepage-notconnected-background-mobile {
    visibility: hidden;
    height: 0;
  }
  .ks-homepage-notconnected-topBtn {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    width: responsiveSizeOnlyWidth(545);
    height: responsiveSizeOnlyWidth(96);
    border-bottom-left-radius: responsiveSizeOnlyWidth(68);
    .mainBtn {
      font-weight: 700;
      font-family: 'Avenir';
      font-size: responsiveSizeOnlyWidth(22);
      width: responsiveSizeOnlyWidth(221);
      height: responsiveSizeOnlyWidth(49);
      &:first-child {
        margin-right: responsiveSizeOnlyWidth(25);
      }
      &.hp-homepage-connectionBtn {
        background-color: black;
        border: responsiveSizeOnlyWidth(2) solid white;
      }
    }
  }
  .hp-homepage-notconnected-background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: responsiveSizeOnlyWidth(1920);
    height: responsiveSizeOnlyWidth(3508);
    visibility: visible;
  }
  .awlad_video {
    position: absolute;
    width: responsiveSizeOnlyWidth(1334);
    height: responsiveSizeOnlyWidth(749);
    z-index: 2;
    margin-top: responsiveSizeOnlyWidth(200);
    margin-left: responsiveSizeOnlyWidth(293);
    border: none;
  }

  .hp-foulane,
  .hp-guides,
  .hp-mshow,
  .hp-quiz {
    position: absolute;
    width: responsiveSizeOnlyWidth(652);
    height: responsiveSizeOnlyWidth(336);
    z-index: 2;
    border-radius: responsiveSizeOnlyWidth(28);
  }
  .hp-foulane {
    top: responsiveSizeOnlyWidth(1153);
    left: responsiveSizeOnlyWidth(270);
  }
  .hp-guides {
    top: responsiveSizeOnlyWidth(1153);
    right: responsiveSizeOnlyWidth(270);
  }
  .hp-mshow {
    top: responsiveSizeOnlyWidth(1691);
    left: responsiveSizeOnlyWidth(270);
  }
  .hp-quiz {
    top: responsiveSizeOnlyWidth(1691);
    right: responsiveSizeOnlyWidth(270);
  }
  .hp-hoopow-logo {
    position: absolute;
    width: responsiveSizeOnlyWidth(863.85);
    height: responsiveSizeOnlyWidth(221.57);
    z-index: 2;
    top: responsiveSizeOnlyWidth(2220);
    left: responsiveSizeOnlyWidth(276);
  }
  .hp-hoopow-faq {
    position: absolute;
    &, .hp-faq {
      width: responsiveSizeOnlyWidth(898);
    }
    z-index: 2;
    top: responsiveSizeOnlyWidth(2460);
    left: responsiveSizeOnlyWidth(259);
    .hp-faq {
      color: black;
      .hp-faqs-question-answer {
        .hp-faqs-header {
          background-color: white;
          .hp-faqs-plus path {
            fill: black;
          }
        }
        &.hp-faq-selected .hp-faqs-answer {
          height: responsiveSizeOnlyWidth(220);
        }
        .hp-faqs-answer-text {
          font-size: responsiveSizeOnlyWidth(22);
        }
      }
    }
  }
  .hp-homepage-signup {
    position: absolute;
    width: responsiveSizeOnlyWidth(591);
    height: responsiveSizeOnlyWidth(794);
    z-index: 2;
    top: responsiveSizeOnlyWidth(2352);
    right: responsiveSizeOnlyWidth(96);
    .hp-homepage-signup-title {
      width: 100%;
      text-align: center;
      font-family: 'Fredoka One';
      font-size: responsiveSizeOnlyWidth(32);
      margin-bottom: responsiveSizeOnlyWidth(15);
    }
    .hp-homepage-signup-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      height: auto;
      width: responsiveSizeOnlyWidth(591);
      padding-top: responsiveSizeOnlyWidth(30);
      padding-bottom: responsiveSizeOnlyWidth(20);
      border-radius: responsiveSizeOnlyWidth(28);
      .ks-login {
        width: 80%;
        margin-bottom: responsiveSizeOnlyWidth(40);
        .ks-form-group {
          margin-bottom: responsiveSizeOnlyWidth(20);
          .ks-form-label,
          .form-label {
            font-size: responsiveSizeOnlyWidth(20);
          }
          textarea,
          input {
            border: none;
            height: responsiveSizeOnlyWidth(72);
            border-radius: responsiveSizeOnlyWidth(10);
            font-size: responsiveSizeOnlyWidth(20);
            margin-top: responsiveSizeOnlyWidth(5);
            -webkit-appearance: none;
            box-shadow:  0px responsiveSizeOnlyWidth(3) responsiveSizeOnlyWidth(6) rgba(0, 0, 0, 0.16);;
          }
        }
        .ks-formBtn {    
          display: flex;
          justify-content: center;
          height: responsiveSizeOnlyWidth(58);
          margin-top: responsiveSizeOnlyWidth(40);
          .mainBtn {
            font-size: responsiveSizeOnlyWidth(24);
            width: responsiveSizeOnlyWidth(237);
            height: responsiveSizeOnlyWidth(58);
          }
        }
      }
      .hp-homepage-signup-form-freeperiod,
      .hp-homepage-signup-nocontract {
        font-family: 'Avenir';
        font-weight: 900;
        font-size: responsiveSizeOnlyWidth(36);
      }
      .hp-homepage-signup-nocontract {
        font-size: responsiveSizeOnlyWidth(48);
      }
    }
  }
  .hp-homepage-signup-cgu {
    position: absolute;
    z-index: 2;
    font-family: 'Avenir';
    color: white;
    text-decoration: none;
    left: responsiveSizeOnlyWidth(80);
    top: responsiveSizeOnlyWidth(3428);
    font-size: responsiveSizeOnlyWidth(32);
  }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
  .hp-homepage-notconnected {
    background-color: #FFCC7E;
    width: 100%;
    height: responsiveSizeOnlyWidthMobile(2836);
    .hp-homepage-notconnected-background {
      visibility: hidden;
      height: 0;
    }
    .hp-homepage-notconnected-background-mobile {
      visibility: visible;
      position: absolute;
      left: 0;
      z-index: 1;
      top: responsiveSizeOnlyWidthMobile(60);
      width: responsiveSizeOnlyWidthMobile(414);
      height: responsiveSizeOnlyWidthMobile(2836);
    }
    .ks-homepage-notconnected-topBtn {
      width: 100%;
      position: sticky;
      top: 0;
      height: responsiveSizeOnlyWidthMobile(66);
      margin-top: responsiveSizeOnlyWidthMobile(75);
      border-bottom-left-radius: 0;
      .mainBtn {
        font-size: responsiveSizeOnlyWidthMobile(14);
        width: responsiveSizeOnlyWidthMobile(168);
        height: responsiveSizeOnlyWidthMobile(38);
        border-radius: responsiveSizeOnlyWidthMobile(42);
        &:first-child {
          margin-right: responsiveSizeOnlyWidthMobile(15);
        }
        &.hp-homepage-connectionBtn {
          background-color: black;
          border: responsiveSizeOnlyWidthMobile(2) solid white;
        }
      }
    }
    .awlad_video {
      width: 100%;
      height: responsiveSizeOnlyWidthMobile(232);
      margin-top: responsiveSizeOnlyWidthMobile(22);
      margin-left: 0;
    }

    &.noAdHeader {
      .ks-homepage-notconnected-topBtn {
        margin-top: responsiveSizeOnlyWidthMobile(20);
      }
      .awlad_video {
        margin-top: responsiveSizeOnlyWidthMobile(160);
      }
    }
    .hp-foulane,
    .hp-guides,
    .hp-mshow,
    .hp-quiz {
      border-radius: responsiveSizeOnlyWidthMobile(28);
      left: responsiveSizeOnlyWidthMobile(30);
      width: responsiveSizeOnlyWidthMobile(361);
      height: responsiveSizeOnlyWidthMobile(187);
    }

    .hp-foulane {
      top: responsiveSizeOnlyWidthMobile(562);
    }
    .hp-guides {
      top: responsiveSizeOnlyWidthMobile(844);
    }
    .hp-mshow {
      top: responsiveSizeOnlyWidthMobile(1112);
    }
    .hp-quiz {
      top: responsiveSizeOnlyWidthMobile(1380);
    }
    .hp-hoopow-logo {
      width: responsiveSizeOnlyWidthMobile(262.09);
      height: responsiveSizeOnlyWidthMobile(67.22);
      top: responsiveSizeOnlyWidthMobile(1620);
      left: responsiveSizeOnlyWidthMobile(76);
    }
    .hp-hoopow-faq {
      position: absolute;
      &, .hp-faq {
        width: responsiveSizeOnlyWidthMobile(361);
      }
      z-index: 3;
      top: responsiveSizeOnlyWidthMobile(1715);
      left: responsiveSizeOnlyWidthMobile(30);
      .hp-faq {
        .hp-faqs-question-answer {
          margin-bottom: responsiveSizeOnlyWidthMobile(15);
          .hp-faqs-header {
            font-size: responsiveSizeOnlyWidthMobile(15);
            height: responsiveSizeOnlyWidthMobile(45);
            svg.hp-faqs-plus {
              width: responsiveSizeOnlyWidthMobile(36);
              height: responsiveSizeOnlyWidthMobile(36);
            }
          }
          &.hp-faq-selected {
            .hp-faqs-answer {
              height: responsiveSizeOnlyWidthMobile(90);
            }
          }
          .hp-faqs-answer-text {
            font-size: responsiveSizeOnlyWidthMobile(10);
          }
        }
      }
    }
    .hp-homepage-signup {
      width: responsiveSizeOnlyWidthMobile(414);
      height: responsiveSizeOnlyWidthMobile(648);
      top: responsiveSizeOnlyWidthMobile(2088);
      left: responsiveSizeOnlyWidthMobile(0);
      .hp-homepage-signup-title {
        font-size: responsiveSizeOnlyWidthMobile(23);
        margin-top: responsiveSizeOnlyWidthMobile(26);
      }
      .hp-homepage-signup-form {
        width: responsiveSizeOnlyWidthMobile(414);
        padding-top: responsiveSizeOnlyWidthMobile(20);
        padding-bottom: responsiveSizeOnlyWidthMobile(20);
        border-radius: 0;
        .ks-login {
          margin-bottom: responsiveSizeOnlyWidthMobile(20);
          .ks-form-group {
            margin-bottom: responsiveSizeOnlyWidthMobile(14);
            .ks-form-label,
            .form-label {
              font-size: responsiveSizeOnlyWidthMobile(15);
            }
            textarea,
            input {
              height: responsiveSizeOnlyWidthMobile(52);
              border-radius: responsiveSizeOnlyWidthMobile(10);
              font-size: responsiveSizeOnlyWidthMobile(14);
              margin-top: responsiveSizeOnlyWidthMobile(5);
              box-shadow:  0px responsiveSizeOnlyWidthMobile(3) responsiveSizeOnlyWidthMobile(6) rgba(0, 0, 0, 0.16);;
            }
          }
          .ks-formBtn {
            height: responsiveSizeOnlyWidthMobile(42);
            margin-top: responsiveSizeOnlyWidthMobile(25);
            .mainBtn {
              font-size: responsiveSizeOnlyWidthMobile(17);
              width: responsiveSizeOnlyWidthMobile(171);
              height: responsiveSizeOnlyWidthMobile(42);
              border-radius: responsiveSizeOnlyWidthMobile(42);
            }
          }
        }
        .hp-homepage-signup-form-freeperiod,
        .hp-homepage-signup-nocontract {
          font-size: responsiveSizeOnlyWidthMobile(24);
        }
        .hp-homepage-signup-nocontract {
          font-size: responsiveSizeOnlyWidthMobile(32);
        }
      }
    }
    .hp-homepage-signup-cgu {
      color: white;
      left: responsiveSizeOnlyWidthMobile(33);
      top: responsiveSizeOnlyWidthMobile(2807);
      font-size: responsiveSizeOnlyWidthMobile(15);
    }
  }
}