@import '../../common.scss';

.hp-faq {
  display: flex;
  color: white;
  font-family: 'Avenir';
  font-weight: 900;
  flex-direction: column;
  @include responsive-size(width, 747.45);
  @include responsive-size(height, 552.56);
  .hp-faqs-question-answer {
    display: flex;
    flex-direction: column;
    position: relative;
    &:not(:last-child) {
      @include responsive-size(margin-bottom, 21);
    }
    .hp-faqs-header {
      display: flex;
      align-items: center;
      border: solid white;
      @include responsive-size(border-width, 3);
      @include responsive-size(font-size, 23);
      @include responsive-size(height, 87.7);
      .hp-faqs-question {
        margin-left: 3%;
      }
    }
    .hp-faqs-answer {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.5s ease, opacity 0.5s ease;
      color: black;
      font-weight: 300;
      background-color: white;
      .hp-faqs-answer-text {
        @include responsive-size(padding, 20);
        @include responsive-size(font-size, 22);
      }
    }
    .hp-faqs-plus {
      position: absolute;
      transition: transform 0.3s ease;
      transform: rotate(45deg);
      @include responsive-size(right, 21);
      @include responsive-size(width, 58.09);
      @include responsive-size(height, 58.09);
      path {
        fill: white;
      }
    }
    &.hp-faq-selected {
      .hp-faqs-plus {
        transform: rotate(90deg);
      }

      .hp-faqs-answer {
        opacity: 1;
        text-align: justify;
        @include responsive-size(height, 250);
      }
    }
  }
}