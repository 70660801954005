@import '../../common.scss';

.hp-eidgift-modal {
  &.ReactModalPortal {
    position: fixed;
    z-index: 10;
    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.8) !important;
      .ReactModal__Content {
        margin: 0 auto;
        border: none !important;

        @include media("<" + $min_mobile) { 
          top: 0 !important;
          left: 0 !important;
          bottom: 0 !important;
          right: 0 !important;
        }
      }
    }
  }
}
.hp-eidgift {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: linear-gradient(#3c74b7 0%, #c27add 100%) !important;
  @include responsive-size(width, 1530);
  @include responsive-size(height, 1145);
  @include media("<" + $min_mobile) {
    width: 100vw;
    height: 88vh;
  }
  .hp-eidgift-logo {
    @include responsive-size(margin-top, 29);
    @include responsive-size(width, 595.8);
    @include responsive-size(height, 152.82);

    @include media("<" + $min_mobile) { 
      @include responsive-size-mobile(margin-top, 11);
      @include responsive-size-mobile(width, 221.51);
      @include responsive-size-mobile(height, 56.82);
    }
  }
  .hp-closeBtn {
    position: absolute;
    @include responsive-size(right, 68);
    @include responsive-size(width, 121.43);
    @include responsive-size(height, 121.43);
    @include responsive-size(top, 45);
    @include media("<" + $min_mobile) { 
      @include responsive-size-mobile(margin-top, 0);
      @include responsive-size-mobile(width, 56);
      @include responsive-size-mobile(height, 56);
    }
    path {
      fill: white;
    }
  }
}