@import '../../common.scss';

.ks-embedded {
  .hp-myorders {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .hp-myorders-title {
      display: flex;
      align-items: center;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(31);
      margin-top: responsiveSizeToV2(30);
      margin-bottom: responsiveSizeToV2(60);
      svg {
        width: responsiveSizeToV2(69);
        height: responsiveSizeToV2(69);
        margin-right: responsiveSizeToV2(10);
      }
    }
    .hp-myorders-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      align-items: center;
      box-sizing: border-box;
      justify-content: space-evenly;
      max-height: responsiveSizeToV2(1000);
      padding-left: responsiveSizeToV2(85);
      padding-right: responsiveSizeToV2(85);
      padding-bottom: responsiveSizeToV2(85);
      .hp-myorders-element {
        width: 50%;
        height: responsiveSizeToV2(330);
        .hp-myorders-empty {
          margin-right: 0;
          margin-left: responsiveSizeToV2(30);
        }
        &.odd {
          display: flex;
          justify-content: flex-end;
          .hp-myorders-empty {
            margin-right: responsiveSizeToV2(30);
            margin-left: 0;
          }
        }
      }
      .separator {
        width: 100%;
        height: 1px;
        background-color: #D8D8D8;
        margin-top: responsiveSizeToV2(50);
        margin-bottom: responsiveSizeToV2(50);
      }
    }
  }
}