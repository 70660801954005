@import '../../../common.scss';

body.embeddedBody .ks-embedded.hp-v3 {
  &.hp-embeddedcontactus {
    .ks-content {
      align-items: flex-start;
    }
    .hp-embeddedcontactus-content {
      font-family: 'Avenir';
      .hp-contactus {
        background: none;
        .ks-form {
          height: auto;
          width: responsiveSizeToV2(860);
          margin-top: responsiveSizeToV2(10);
          .ks-form-group-checkbox {
            margin-bottom: responsiveSizeToV2(20);
          }
        }
      }
      .ks-form-title,
      .hp-closeBtn {
        display: none;
      }
      .hp-embeddedcontactus-content-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: responsiveSizeToV2(22);
        margin-top: responsiveSizeToV2(15);
        svg {
          width: responsiveSizeToV2(49);
          height: responsiveSizeToV2(49);
          margin-right: responsiveSizeToV2(10);
        }
      }
    }
  }
}