@import '../../common.scss';

.hp-eidgift-customer-card {
  position: relative;
  &, svg {
    @include responsive-size(width, 941);
    @include responsive-size(height, 670);
    @include media("<" + $min_mobile) {
      @include responsive-size-mobile(width, 355);
      @include responsive-size-mobile(height, 255);
    }
  }
  label {
    position: absolute;
    z-index: 10;
    font-family: 'Avenir';
    font-weight: 700;
    text-align: center;
    @include responsive-size(top, 480);
    @include responsive-size(left, 231);
    @include responsive-size(width, 480);
    @include responsive-size(height, 78);
    @include responsive-size(font-size, 57);
    @include media("<" + $min_mobile) {
      @include responsive-size-mobile(top, 185);
      @include responsive-size-mobile(left, 87);
      @include responsive-size-mobile(width, 180);
      @include responsive-size-mobile(height, 20);
      @include responsive-size-mobile(font-size, 20);
    }
  }
}