@import '../../common.scss';
.hp-member{
  background-color: #F2F2F2;
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Inter';
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  .hp-memberBilling {
    .mainBtn {
      color: #C3C3C3;
      font-weight: 700;
      font-family: 'Avenir';
      border: solid #C3C3C3;
      background-color: white;
      @include responsive-size(padding-top, 11);
      @include responsive-size(padding-bottom, 11);
      @include responsive-size(padding-left, 44);
      @include responsive-size(padding-right, 44);
      @include responsive-size(font-size, 19);
      @include responsive-size(border-width, 2);
    }
  }
  .hp-member-title {
    color: #808080;
    font-family: 'Avenir';
    border-bottom: 1px solid #808080;
    @include responsive-size(font-size, 35);
  }

  .hp-member-title,
  .hp-member-boxes {
    width: 92.5%;
  }
  .hp-member-boxes {
    display: flex;
    justify-content: space-between;
    @include responsive-size(margin-top, 40);
    .hp-memberDetails,
    .hp-memberBilling {
      @include responsive-size(width, 673);
      @include responsive-size(height, 757);
    }
    .hp-memberDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      @include responsive-size(border-radius, 28);
      .hp-memberForm {
        width: 75%;
      }
    }
    .hp-memberBilling {
      font-family: 'Avenir';
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .hp-member-contract,
      .hp-member-cb,
      .hp-member-bills {
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include responsive-size(border-radius, 28);
      }
      .hp-member-contract {
        color: black;
        @include responsive-size(height, 274);
        .hp-planLoader {
          &, span {
            @include responsive-size(height, 70);
            @include responsive-size(width, 70);
            @include responsive-size(border-width, 4);
          }
        }
        &.hp-free-period {
          background-color: #51AABC;
          span {
            color: white;
            font-weight: 700;
            font-family: 'Avenir';
            @include responsive-size(font-size, 29);
          }
          button.mainBtn {
            color: #51AABC;
            border: solid #C3C3C3;
            background-color: white;
            @include responsive-size(margin-top, 13);
            @include responsive-size(border-radius, 68);
            @include responsive-size(font-size, 27);
            @include responsive-size(border-width, 2);
            @include responsive-size(width, 278);
            @include responsive-size(height, 68);
          }
        }
        .hp-member-contract-type {
          font-weight: 900;
          text-decoration: underline;
          @include responsive-size(font-size, 22);
          @include responsive-size(margin-bottom, 5);
        }
        .hp-member-contract-desc {
          font-weight: 700;
          @include responsive-size(font-size, 20);
          @include responsive-size(margin-bottom, 20);
          label {
            color: #51AABC;
          }
        }
      }
      .hp-member-cb {
        @include responsive-size(height, 235);
        svg {
          @include responsive-size(width, 385.86);
          @include responsive-size(height, 43.56);
        }
      }
      .hp-member-gift-panels {
        display: flex;
        justify-content: space-between;
        @include responsive-size(height, 200);
        .hp-member-gift-cards-area,
        .hp-member-gift-code {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @include responsive-size(width, 321);
          @include responsive-size(border-radius, 28);
          .mainBtn {
            background-color: white;
            border: 2px solid #C3C3C3;
            text-transform: uppercase;
            @include responsive-size(width, 202);
            @include responsive-size(height, 47);
          }
        }
      
        .hp-member-gift-code {
          background-color: #9178CF;
          textarea {
            color: white;
            resize: none;
            font-weight: 900;
            background-color: black;
            text-transform: uppercase;
            text-align: center;
            font-family: 'Avenir';
            @include responsive-size(width, 238);
            @include responsive-size(height, 83);
            @include responsive-size(font-size, 25);
            @include responsive-size(border-radius, 7);
            @include responsive-size(margin-bottom, 13);
          }
          .mainBtn {
            color: #9178CF;
            @include responsive-size(padding-top, 9);
            &.OK {
              color: white;
              background-color: #51AABC;
            }
            &.Erreur {
              color: white;
              background-color: #FB636F;
            }
          }
        }
      
        .hp-member-gift-cards-area {
          background-color: #5876BF;
          position: relative;
          color: white;
          font-family: 'Avenir';
          font-weight: 700;
          @include responsive-size(font-size, 35);
          text-align: center;
          line-height: 1.1;
          label { 
            @include responsive-size(margin-top, 5);
          }
          .mainBtn {
            color: #5876BF;
            z-index: 1;
            @include responsive-size(margin-top, 20);
            @include responsive-size(padding-top, 8);
          }
          svg {
            position: absolute;
            right: 0;
            z-index: 0;
            @include responsive-size(bottom, 13);
            @include responsive-size(width, 83);
            @include responsive-size(height, 83);
          }
        }
      }
    }
  }
}

button[disabled] {
  cursor: not-allowed !important;
}

.hp-member-bills-title,
.hp-member-cb-title,
.hp-member-contract-title {
  color: #808080;
  @include responsive-size(font-size, 35);
  @include responsive-size(margin-bottom, 15);
}

.hp-member-plans-faq {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  @include responsive-size(margin-top, 124);
}

.hp-member-cancel-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir';
  background-color: white;
  @include responsive-size(width, 673);
  @include responsive-size(height, 200);
  @include responsive-size(margin-top, 280);
  @include responsive-size(border-radius, 28);
  @include responsive-size(font-size, 24);
  .hp-member-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive-size(margin-top, 20);
    .mainBtn {
      @include responsive-size(font-size, 19);
      &.hp-plan-cancel-okBtn,
      &.hp-plan-cancel-noBtn {
        color: #C3C3C3;
        font-weight: 700;
        font-family: 'Avenir';
        border: solid #C3C3C3;
        background-color: white;
        @include responsive-size(margin-left, 20);
        @include responsive-size(border-width, 2);
      }
      &.hp-plan-cancel-okBtn {
        color: white;
        margin-left: 0;
        border: solid #51AABC;
        background-color: #51AABC;
      }
    }
  }
}
