@import '../../common.scss';

.ks-content {
  &.hp-homePage {
    background-size: cover;
    background-image: url('../../assets/v2/contactus.jpg');
    background-color: rgba($color: #D0BF87, $alpha: 0.6);
    background-blend-mode: multiply;
    
    .ks-signUpTitle {
      text-align: center;
      font-family: 'Avenir';
      line-height: 1;
      color: white;
      font-weight: 900;
      @include responsive-size(font-size, 34);
    }
    .ks-signUpSubTitle {
      text-align: center;
      font-family: 'Avenir';
      color: white;
      font-weight: 300;
      @include responsive-size(font-size, 29);
      @include responsive-size(margin-bottom, 16);
    }
    .ks-login {
      display: flex;
      height: auto;
      align-items: center;
      box-sizing: border-box;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      @include responsive-size(border-radius, 28); 
      @include responsive-size(width, 784);
      @include responsive-size(padding-top, 37);
      .ks-form {
        font-family: 'Avenir';
        @include responsive-size(font-size, 22);
        width: 64%;
        .ks-form-group:last-child {
          margin-bottom: 0;
        }
      }
      .ks-formBtn {
        display: flex;
        flex-direction: column;
        .mainBtn {
          font-family: 'Avenir';
          background-color: #51AABC;
          font-weight: 700;
          display: flex;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          @include responsive-size(height, 58);
          @include responsive-size(width, 240);
          @include responsive-size(font-size, 24); 
        }
        @include responsive-size(margin-top, 37); 
        @include responsive-size(margin-bottom, 37); 
      }
    }
  }
}