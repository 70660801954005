@import '../../common.scss';

.hp-layoutV2 .ks-reset-password {
  width: 80%;
  max-width: 400px;
  &__title {
    width: 100%;
    text-align: center;
    font-family: 'Fredoka One';
    font-size: 17px;
    margin-bottom: 14px;
  }
  &__desc {
    font-size: 15px;
    text-align: center;
    font-family: 'Avenir';
    margin-bottom: 26px;
  }
  .ks-form-label {
    color: #707070;
    font-size: 16px;
    text-align: center;
    font-family: 'Avenir';
  }
  .ks-form-group {
    margin-bottom: 18px;
    .password-wrapper {
      .eye-icon-wrapper {
        width: 32px;
        height: 32px;
        .eye-icon {
          font-size: 24px;
        }
      }
    }
  }
  input.ks-form-control {
    font-size: 15px;
    height: 32px;
    border-radius: 7px;
    border: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  }
  .ks-formBtn {
    display: flex;
    justify-content: center;
    .mainBtn {
      width: 136px;
      height: 32px;
      font-size: 19px;
      border-radius: 32px;
      font-family: 'Fredoka One';
    }
  }
}