@import '../../common.scss';



.ks-embedded {
  .hp-giftspace{
    .hp-giftspace-header {
      color: white;
      font-weight: 400;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(28);
      display: flex;
      align-items: center;
      margin-top: responsiveSizeToV2(40);
      margin-bottom: responsiveSizeToV2(20);
      svg {
        width: responsiveSizeToV2(56);
        height: responsiveSizeToV2(56);
        margin-right: responsiveSizeToV2(10);
      }
    }
    .hp-eidgift-plans-faq {
      margin-top: responsiveSizeToV2(46);
    }
    .hp-eidgift-card-wrapper {
      .main-slider {
        margin-top: responsiveSizeToV2(42);
      }
    }
    .hp-giftspace-tabs {
      display: flex;
      color: white;
      font-family: 'Avenir';
      font-weight: 900;
      margin-top: responsiveSizeToV2(16);
      font-size: responsiveSizeToV2(28);
      .hp-giftspace-tab-item {
        opacity: 0.3;
        &:first-child {
          margin-right: responsiveSizeToV2(33);
        }
        &:last-child {
          margin-left: responsiveSizeToV2(33);
        }
        &.selected {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}