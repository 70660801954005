@import '../../common.scss';

.hp-v3 {
  .ks-content.desktop-only {

    .mobile-only.mobile-only.mobile-only {
      display: none;
    }

    &.hp-homePage {
      background: none;
      background-color: #1C1D1E;
    }

    .ks-loginV3 {
      height: responsiveSizeToV2(650);
      min-width: responsiveSizeToV2(540);

      .ks-sign-in {
        width: responsiveSizeToV2(540);
      }
    }

    .ks-embedded-authentication {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: responsiveSizeToV2(1020);
      height: responsiveSizeToV2(820);

      .ks-form-title {
        display: flex;
        color: #DDDDDD;
        align-items: center;
        margin-left: responsiveSizeToV2(100);
        margin-bottom: responsiveSizeToV2(20);
        font-size: responsiveSizeToV2(25);

        span {
          cursor: pointer;
          font-family: 'Fredoka One';
        }

        span.selected {
          color: #51AABC;
          cursor: default;
          text-decoration: underline;
        }
      }

      .ks-loginV3 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        background-color: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: responsiveSizeToV2(42);

        .ks-form-left {
          display: flex;
          flex-direction: column;
          width: responsiveSizeToV2(540);
          height: responsiveSizeToV2(654);

          .ks-form {
            display: flex;
            flex-direction: column;
            width: 92%;

            .ks-form-group {
              position: relative;
              margin-bottom: responsiveSizeToV2(46);

              input.ks-form-control {
                border: none;
                background-color: #F2F2F2;

                &:disabled {
                  opacity: 0.5;
                }
              }

              .ks-form-error {
                display: block;
                position: absolute;
                bottom: responsiveSizeToV2(-40);
                left: responsiveSizeToV2(8);
                font-size: responsiveSizeToV2(29);
                font-weight: 500;
                color: #bf594c;
                font-family: "Avenir";

                &__small {
                  font-size: responsiveSizeToV2(26);
                }

                &__overflow {
                  bottom: responsiveSizeToV2(-53);
                  line-height: 0.95;
                }
              }

              .checked,
              .unchecked,
              .warning {
                display: none;
                position: absolute;
                left: responsiveSizeToV2(-8.4-38);
                bottom: responsiveSizeToV2(12.7);
                width: responsiveSizeToV2(38);
                height: responsiveSizeToV2(38);
              }

              .warning {
                right: responsiveSizeToV2(11.7);
                height: responsiveSizeToV2(34);
                left: unset;
              }

              .unchecked {
              }

              &.valid {
                .checked {
                  display: block;
                }
              }

              &.invalid {
                input {
                  color: #bf594c;
                  border-style: solid;
                  border-color: #bf594c;
                  border-width: responsiveSizeToV2(2);
                  background-color: #f5e2df;
                }

                .unchecked {
                  display: block;
                }

                .warning {
                  display: block;
                }
              }

              &.unknown {
              }
            }
          }

          .ks-formBtn {
            width: 92%;
            display: flex;
            justify-content: center;
            margin-top: responsiveSizeToV2(15);

            .mainBtn {
              font-weight: 900;
              font-family: 'Avenir';
              width: responsiveSizeToV2(226);
              height: responsiveSizeToV2(54);
              font-size: responsiveSizeToV2(26);
            }
          }

          .ks-forgetBtn {
            font-size: responsiveSizeToV2(19);
            font-style: normal;
          }
        }

        .ks-form-right {
          background-color: #51AABC;
          width: responsiveSizeToV2(360);
          height: responsiveSizeToV2(654);
          border-radius: responsiveSizeToV2(20);
        }

        .ks-signin-img {
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .ks-content.mobile-only {
    .desktop-only.desktop-only.desktop-only {
      display: none;
    }

    &.hp-homePage {
      background: none;
      background-color: #1C1D1E;
    }

    .ks-loginV3 {
      //height: responsiveSizeToV2(650);
      //min-width: responsiveSizeToV2(540);
      width: 92%;

      .ks-sign-in {
        //width: 92%;
      }
    }

    .ks-embedded-authentication {
      position: relative;
      display: flex;
      flex-direction: column;
      //justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: responsiveSizeToV2(44);

      .ks-form-title {
        margin-top: responsiveSizeToV2(66);
        display: flex;
        gap: responsiveSizeToV2(93);
        color: #c3c3c3;
        align-items: center;
        margin-bottom: responsiveSizeToV2(56);
        font-size: responsiveSizeToV2(47);
        line-height: responsiveSizeToV2(50);
        font-size: 950;
        font-family: 'Avenir';

        span {
          display: block;
          cursor: pointer;
          font-family: 'Fredoka One';
        }

        span.selected {
          color: #51AABC;
          cursor: default;
          text-decoration: underline;
        }
      }

      .ks-loginV3 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        background-color: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: responsiveSizeToV2(42);

        .ks-form-left {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;

          .ks-form {
            display: flex;
            flex-direction: column;
            gap: responsiveSizeToV2(56);
            padding: 0;
            margin: 0;

            .ks-form-group {
              font-size: responsiveSizeToV2(36);
              position: relative;

              .ks-form-error {
                display: none
              }

              .checked,
              .unchecked,
              .warning {
                display: none;
              }

              label {
                margin-left: responsiveSizeToV2(35);
                text-transform: uppercase;
                font-size: inherit;
              }

              .ks-form-label {
                font-size: inherit;
                font-weight: 950;
              }

              .ks-form-control {
                font-size: inherit;
                font-weight: 500;
                height: responsiveSizeToV2(89);
              }

              input.ks-form-control {
                border-width: 0;
                background-color: #F2F2F2;
                border-radius: responsiveSizeToV2(15);
                font-size: inherit;

                &:disabled {
                  opacity: 0.5;
                }

                &.valid {
                  background-color: #d8f8fd;
                  border-width: 4;
                  background-color: #51aabc;
                }
              }
            }
          }

          .ks-separator {
            height: responsiveSizeToV2(3);
            width: responsiveSizeToV2(880);
            background: #f2f2f2;
            margin-bottom: responsiveSizeToV2(19);
          }

          .ks-avatar-selector-btn {
            border-width: 0;
            border-color: transparent;
            height: responsiveSizeToV2(160);
            width: 100%;
            display: flex;
            font-size: responsiveSizeToV2(36);
            font-weight: 950;
            border-radius: responsiveSizeToV2(15);
            justify-content: center;
            align-items: center;
            position: relative;
            background: transparent;

            span {
              width: 100%;
              height: responsiveSizeToV2(89);
              display: flex;
              align-items: center;
              background-color: #eac676;
              border-radius: responsiveSizeToV2(15);
              padding-left: responsiveSizeToV2(180);
            }

            svg,
            img {
              display: block;
              position: absolute;
              left: responsiveSizeToV2(-8);
              top: 0;
              bottom: 0;
              margin-top: auto;
              margin-bottom: auto;
              width: responsiveSizeToV2(156);
              height: responsiveSizeToV2(160);
              border-radius: responsiveSizeToV2(78);
            }
          }

          .ks-formBtn {
            width: 92%;
            display: flex;
            justify-content: center;
            margin-top: responsiveSizeToV2(55);

            .mainBtn {
              font-weight: 950;
              font-family: 'Avenir';
              width: responsiveSizeToV2(516);
              height: responsiveSizeToV2(120);
              font-size: responsiveSizeToV2(47);
              border-radius: responsiveSizeToV2(18);
            }
          }

          .ks-forgetBtn {
            font-size: responsiveSizeToV2(35);
            font-style: normal;
            text-decoration: none;
          }
        }

        .ks-form-right {
          display: none;
        }

        .ks-avatar-selector {
          background: #eac676;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }

        .ks-signin-img {
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &.ks-embedded {
    .ks-login {
      box-shadow: none;

      .ks-formBtn {
        display: flex;
        align-items: center;
        flex-direction: column;

        .mainBtn {
          font-family: 'Avenir';
          font-weight: 700;
          display: flex;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: responsiveSizeToV2(58);
          width: responsiveSizeToV2(240);
          font-size: responsiveSizeToV2(24);
        }

        .ks-forgetBtn {
          border: none;
          background-color: transparent;
          font-family: 'Avenir';
          font-style: oblique;
          text-decoration: underline;
          font-size: responsiveSizeToV2(13);
          margin-top: responsiveSizeToV2(13);
        }
      }
    }

    .ks-loginTitle,
    .hp-signin-closeBtn {
      display: none;
    }
  }
}