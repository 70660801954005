@import '../../common.scss';

.hp-memberForm {
  width: 100%;
  .hp-profil{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .ks-form-group {
      width: 100%;
      @include responsive-size(margin-bottom, 19);
    }
  }
  label {
    font-family: 'Avenir';
    font-weight: 700;
    @include responsive-size(font-size, 22);
  }
  textarea,
  input {
    @include responsive-size(font-size, 20);
  }
  .mainBtn {
    font-family: 'Avenir';
    font-weight: 700;
    @include responsive-size(font-size, 24);
    @include responsive-size(width, 411);
    @include responsive-size(height, 58);
  }
}