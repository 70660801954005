@import '../../../common.scss';

.hp-blockedModal-popup__header.desktop-only {
  display: flex;
  align-items: center;
  font-family: 'Avenir';
  font-size: responsiveSizeToV2(23);
  margin-top: responsiveSizeToV2(25);
  margin-bottom: responsiveSizeToV2(22);

  .hp-blockedModal-popup__header-icon {

    &,
    svg {
      width: auto;
      height: responsiveSizeToV2(120);
      min-width: responsiveSizeToV2(120);
    }
  }

  .hp-blockedModal-popup__header-text {
    margin-left: responsiveSizeToV2(21);
  }

  .hp-blockedModal-popup__header-desc {
    font-size: responsiveSizeToV2(19);
  }
}