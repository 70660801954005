@import '../../../common.scss';

.ks-embedded {
  .hp-myorders-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 95%;
    height: 100%;
    .hp-myorders-item-desc {
      width: 100%;
      display: flex;
      align-items: center;
      font-family: 'Avenir';
      height: responsiveSizeToV2(170);
      font-size: responsiveSizeToV2(27);
      svg, img {
        width: responsiveSizeToV2(170);
        height: responsiveSizeToV2(170);
        margin-right: responsiveSizeToV2(15);
      }
      .hp-myorders-item-desc-title {
        font-weight: 900;
        font-size: responsiveSizeToV2(30);
      }
      .hp-myorders-item-desc-text {
        font-size: responsiveSizeToV2(21);
      }
    }
    .hp-myorders-item-orderdate,
    .hp-myorders-item-ordertype {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #DDDDDD;
      height: responsiveSizeToV2(55);
      font-size: responsiveSizeToV2(24);
      border-radius: responsiveSizeToV2(12);
    }
  }
}