@import '../../common.scss';

.hp-appCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include responsive-size(margin-bottom, 30);
  &, svg {
    @include responsive-size(width, 271);
    @include responsive-size(height, 271);
  }
  .ks-appCard-thumbnail-landscape {
    display: block;
  }
  .ks-appCard-thumbnail-portrait {
    display: none;
  }
  .hp-appCard-empty {
    display: flex;
    align-items: flex-end;
    .hp-appCard-emptyBox {
      width: 100%;
      opacity: 0.9;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #591927;
      @include responsive-size(width, 254);
      @include responsive-size(height, 254);
      @include responsive-size(border-radius, 36);
      @include responsive-size(margin-bottom, 15);
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #591927;
        font-weight: 900;
        font-family: 'Avenir';
        background-color: white;
        @include responsive-size(width, 156);
        @include responsive-size(height, 36);
        @include responsive-size(font-size, 16);
        @include responsive-size(border-radius, 5);
      }
    }
  }
  .hp-cardLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #591927;
    @include responsive-size(width, 254);
    @include responsive-size(height, 254);
    @include responsive-size(border-radius, 36);
    @include responsive-size(margin-bottom, 15);
    & > span {
      box-shadow: inset 0px 0px 0px 2px white;
      &::after,
      &::before {
        background-color: white;
      }
    }
  }
}

.portrait {
  .ks-appCard-thumbnail-landscape {
    display: none;
  }
  .ks-appCard-thumbnail-portrait {
    display: block;
  }
}