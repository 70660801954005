@import '../../../../common.scss';

.main-slider {
	overflow: hidden;
	@include responsive-size(height, 600);
	@include responsive-size(width, 600);
	@include media("<=phone") {
		height: initial;
		width: initial;
		@include responsive-size-mobile(margin-top, 100);
	}
	&__swiper {
		display: flex;
		overflow-x: visible;
		transition-property: transform;
		will-change: transform;
		@include media("<=phone") {
			height: initial;
			display: -webkit-inline-box;
		}
	}
	&__arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		@include responsive-size(width, 50);
		@include responsive-size(height, 50);
		cursor: pointer;
		&--prev {
			left: 20%;
		}
		&--next {
			right: 20%;
		}
	}
}

.content-slide {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}
