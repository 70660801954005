@import "../../common.scss";

.hp-layoutV2 {
  .hp-homepageV3 {
    .hp-appCards,
    .hp-appSoon-title {
      border-top: 1px solid #707070;
      margin-top: responsiveSizeToV2(20);
      padding-top: responsiveSizeToV2(14);
    }

    .hp-appNews,
    .hp-appCards,
    .hp-appSoon {
      margin-left: responsiveSizeToV2(40);
      margin-right: responsiveSizeToV2(40);
    }
    .hp-appNews-title {
      display: flex;
      align-items: center;
    }
    .hp-appNews,
    .hp-appCards,
    .hp-appSoon {
      font-family: 'Avenir';
      &-frame {
        display: flex;
      }
      &-title {
        color: white;
        font-size: responsiveSizeToV2(17);
        font-family: 'Avenir';
        font-weight: 700;
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }
    }
    a {text-decoration: none;}
    .hp-appNews {
      margin-top: responsiveSizeToV2(31);
      height: responsiveSizeToV2(240);
      .hp-appNews-frame {
        width: 100%;
        height: responsiveSizeToV2(240);
        overflow: hidden;
        .hp-appNews-banner {
          width: 100%;
          height: responsiveSizeToV2(240);
        }
        .hp-appNews-line {
          display: flex;
          position: relative;
          box-sizing: border-box;
          font-family: 'Avenir';
          background-color: #1C1D1E;
          width: responsiveSizeToV2(420);
          height: responsiveSizeToV2(240);
          padding: responsiveSizeToV2(20);
          font-size: responsiveSizeToV2(17);
          border-radius: responsiveSizeToV2(10);
          &:not(:first-child) {
            margin-left: responsiveSizeToV2(20);
          }
          color: #808080;
          font-weight: 900;
          line-height: 1.4;
          img {
            min-width: inherit;
            height: responsiveSizeToV2(200);
            width: responsiveSizeToV2(200);
            border-radius: responsiveSizeToV2(10);
          }
          .hp-appNews-line-frame {
            margin-left: responsiveSizeToV2(17);
            margin-top: responsiveSizeToV2(20);
            .hp-appNews-line-appName { text-transform: uppercase; }
            .hp-appNews-line-title {
              color: white;
            }
            .hp-appNews-line-description {
              line-height: 1.2;
              font-weight: 400;
              margin-top: responsiveSizeToV2(3);
              font-size: responsiveSizeToV2(14);
            }
          }
          
        }
      }
    }

    .hp-appCards {
      .hp-appCards-frame {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: responsiveSizeToV2(250);
        margin-top: responsiveSizeToV2(19);
        margin-bottom: responsiveSizeToV2(36);
        .hp-appCards-line {
          display: flex;
          width: responsiveSizeToV2(298);
          height: responsiveSizeToV2(108);
          &, svg {
            cursor: pointer;
          }
          &.ks-disabled {
            opacity: 1;
          }
          &:not(:nth-child(5)) {
            margin-left: responsiveSizeToV2(37);
          }
          &:first-child {
            margin-left: 0;
            margin-bottom: responsiveSizeToV2(32);
          }
          .hp-appCards-line-app-logo div {
            height: 100%;
            display: flex;
            align-items: center;
            &, svg {
              width: responsiveSizeToV2(100);
            }
            svg {
              height: responsiveSizeToV2(100);
            }
          }
          &-frame {
            margin-left: responsiveSizeToV2(20);
            .hp-appCards-line-title {
              color: #DDDDDD;
              font-weight: 900;
              font-size: responsiveSizeToV2(17);
            }
            .hp-appCards-line-description {
              color: #C3C3C3;
              line-height: 1.1;
              font-size: responsiveSizeToV2(14);
            }
            .hp-appCards-line-btn {
              width: auto;
              height: responsiveSizeToV2(29);
              margin-top: responsiveSizeToV2(8);
            }
          }
        }
      }
    }

    .hp-appSoon {
      .hp-appSoon-frame {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: responsiveSizeToV2(300);
        margin-top: responsiveSizeToV2(23);
        .hp-appSoon-ad-frame {
          &.ks-disabled {
            opacity: 1;
          }
          color: #808080;
          background-color: #1C1D1E;
          width: responsiveSizeToV2(420);
          height: responsiveSizeToV2(300);
          border-radius: responsiveSizeToV2(10);
          .hp-appSoon-ad-image {
            &, svg {
              width: responsiveSizeToV2(420);
              height: responsiveSizeToV2(220);
            }
          }
          .hp-appSoon-ad-text {
            display: flex;
            font-size: responsiveSizeToV2(17);
            margin: responsiveSizeToV2(7) responsiveSizeToV2(20);
            .hp-appSoon-ad-text-left {
              width: 55%;
              .hp-appSoon-ad-description {color: white;}
            }
            .hp-appSoon-ad-text-right {
              line-height: 1.2;
              width: 45%;
              text-align: right;
              font-size: responsiveSizeToV2(14);
            }
          }
        }
      }
    }
  }
}