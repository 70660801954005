@import './common.scss';

html, body{
  overflow: hidden;
  position: fixed;
  margin: auto;
  height: 100vh;
  max-height: 100vh;
  left: 0;
  right: 0;
}

.hp-layoutV2 {
  overflow: hidden;
  #mainContentScreen {
    z-index: 14 !important;
  }
}
.embeddedBody {
  amplify-authenticator {
    --box-shadow: 0;
  }
  amplify-button {
    display: none;
  }
}

.ReactModalPortal {
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .ReactModal__Overlay {
    position: absolute !important;
    z-index: 100;
    background-color: rgba(81, 170, 188, 0.95) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .ReactModal__Content {
      border: none !important;
      inset: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      background: none !important;
      overflow: hidden !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .hp-closeBtn path {
      fill: white;
    }
  }
}

.ReactModalPortal,
.hp-subscribe,
.hp-member {
  .hp-subscribe-logo,
  .hp-member-logo {
    @include responsive-size(margin-top, 26);
    @include responsive-size(width, 595.8);
    @include responsive-size(height, 152.82);
  }
  .hp-closeBtn {
    position: absolute;
    @include responsive-size(top, 42);
    @include responsive-size(right, 68);
    @include responsive-size(width, 121.43);
    @include responsive-size(height, 121.43);
  }
}

button.mainBtn {
  font-family: 'Fredoka One';
  background-color: #51AABC;
  &:focus {outline:0;}
  cursor: pointer;
  @include responsive-size(border-radius, 30);
  @include responsive-size(padding-left, 16);
  @include responsive-size(padding-right, 16);
  @include responsive-size(padding-top, 6);
  @include responsive-size(padding-bottom, 6);
  border: none;
  color: white;
  &:disabled {
    opacity: 0.4;
  }
}

.disabled {
  opacity: 0.4;
}

.ks-auth-signinBtn {
  font-family: 'Avenir';
  position: absolute;
  z-index: 10;
  font-weight: 900;
  @include responsive-size(font-size, 22);
  @include responsive-size(width, 250);
  @include responsive-size(height, 58);
  @include responsive-size(top, 10);
  @include responsive-size(right, 30);
  @include responsive-size(border-radius, 58); 
}

.ks-auth-topBanner-connectBtn {
  position: absolute;
  text-transform: uppercase;
  @include responsive-size(top, 14); 
  @include responsive-size(right, 25);
  @include responsive-size(width, 221); 
  @include responsive-size(height, 49); 
  @include responsive-size(font-size, 22); 
}

.ks-auth-topBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(34, 23, 49, 0.5);
  &, svg {
    @include responsive-size(height, 82);
  }
  svg { 
    @include responsive-size(width, 230); 
  }
}
.hp-newlettersForm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: white;
  @include responsive-size(width, 784);
  @include responsive-size(height, 254);
  @include responsive-size(border-radius, 28); 
  span {
    color: #51AABC;
    font-family: 'Fredoka One';
    @include responsive-size(font-size, 25);
  }
  textarea,
  input {
    text-align: center;
    font-family: 'Avenir';
    padding-left: 0;
    @include responsive-size(font-size, 25); 
    @include responsive-size(font-size, 20); 
    @include responsive-size(width, 686.86); 
  }
  .mainBtn {
    font-family: 'Avenir';
    font-weight: 900;
    @include responsive-size(width, 429); 
    @include responsive-size(height, 58);
    @include responsive-size(font-size, 24);
  }
}

.ms-clickable,
.ks-clickable {
  cursor: pointer;
}

.ks-disabled,
.ks-not-selected {
  opacity: 0.3;
}

.ks-disabled {
  cursor: not-allowed;
  pointer-events: none; 
}

svg {
  cursor: default;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  .ks-clickable & {
    cursor: pointer;
  }
}

.hp-email-contact {
  position: absolute;
  z-index: 3;
  @include responsive-size(top, 1072);
  @include responsive-size(right, 13);
  &, svg {
    @include responsive-size(width, 73);
    @include responsive-size(height, 51);
  }
}

.ks-content {
  background-color: black;
  background-size: contain;
  position: relative;
  @include responsive-size(width, 1527);
  @include responsive-size(height, 1145);
  textarea,
  input {
    @include responsive-size(font-size, 20); 
  }

	@include media("<480px") {
    // @include responsive-size-mobile(width, 1040);
    // @include responsive-size-mobile(height, 1640);
	}
}

.hp-topBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(34, 23, 49, 0.5);
  &, svg {
    @include responsive-size(height, 82);
  }
  svg { 
    @include responsive-size(width, 230); 
  }
  background-color: transparent;
  .ks-auth-version {
    color: #68A4B1;
  }
}

.hp-advert {
  margin: auto;
  svg.hp-advert-image {
    display: block;
    @include responsive-size(width, 1550);
    @include responsive-size(height, 1150);
    
    @include media("<480px") {
      display: none;
      @include responsive-size-mobile(width, 414);
      @include responsive-size-mobile(height, 680);
    }
  }
  svg.hp-advert-image-mobile {
    display: none;
    @include media("<480px") {
      display: block;
      @include responsive-size-mobile(width, 414);
      @include responsive-size-mobile(height, 680);
    }
  }
  .mainBtn {
    position: absolute;
    color: #1F5BA2;
    background-color: white;
    border: solid #1F5BA2;
    @include responsive-size(border-width, 6);
    @include responsive-size(border-radius, 50);
    @include responsive-size(font-size, 33);
    @include responsive-size(width, 358);
    @include responsive-size(height, 87);
    @include responsive-size(top, 222);
    @include media("<480px") {
      @include responsive-size-mobile(border-width, 3);
      @include responsive-size-mobile(border-radius, 41);
      @include responsive-size-mobile(font-size, 17);
      @include responsive-size-mobile(width, 178);
      @include responsive-size-mobile(height, 41);
      @include responsive-size-mobile(top, 214);
    }
  }
  .hp-closeBtn {
    position: absolute;
    @include responsive-size(top, 42);
    @include responsive-size(right, 130);
    @include responsive-size(width, 121.43);
    @include responsive-size(height, 121.43);
    @include media("<480px") {
      @include responsive-size-mobile(top, 19);
      @include responsive-size-mobile(right, 15);
      @include responsive-size-mobile(width, 55);
      @include responsive-size-mobile(height, 55);
    }
    path {
      fill: white;
    }
  }
  &.ReactModalPortal {
    position: fixed;
    z-index: 10;
    .ReactModal__Content {
      border: none !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
    }
    .ReactModal__Overlay { background-color: rgba(3, 33, 69, 0.95) !important}
  }
}

.ks-content {
  margin: auto;
  &.hp-homePage {
    background-image: url(./assets/hoopow_bg.svg);
    .hp-eidgift-banner-connected {
      position: absolute;
      z-index: 4;
      top: 0;
      @include responsive-size(left, 255);
      @include responsive-size(width, 985);
      @include responsive-size(height, 84);
    }
    .hp-eidgift-closeBtn-banner-connected {
      position: absolute;
      z-index: 5;
      @include responsive-size(top, 19);
      @include responsive-size(left, 250);
      @include responsive-size(width, 39);
      @include responsive-size(height, 39);
    }
    .hp-freeplan-duration {
      position: absolute;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      background-color: #040406;
      color: #FFB561;
      font-family: 'Avenir';
      font-weight: 700;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      @include responsive-size(font-size, 28);
      @include responsive-size(left, 280);
      @include responsive-size(width, 940);
      @include responsive-size(height, 85);
      @include responsive-size(border-bottom-left-radius, 46);
      @include responsive-size(border-bottom-right-radius, 46);
    }
    .hp-topBanner-mobile {
      display: none;
    }
    
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.hp-connected {
      .hp-mainContent {
        z-index: 1;
        display: flex;    
        align-items: center;
        height: 100%;
        .hp-animation {
          position: absolute;
          z-index: 2;
          @include responsive-size(top, 70);
          @include responsive-size(left, 10);
          &, svg {
            @include responsive-size(width, 310);
            @include responsive-size(height, 233);
          }
        }
      }
      .hp-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        &, svg {
          width: 100%;
          height: 100%;
        }
      }
      background-image: none;
      align-items: flex-end;
      justify-content: flex-end;
      
    }
    span.ks-auth-version {
      color: #816BA2;
      position: absolute;
      font-family: 'Avenir';
      @include responsive-size(top, 29); 
      @include responsive-size(left, 260);
      @include responsive-size(font-size, 23); 
    }
    &.hp-mainHomePage {
      .hp-previewApp {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        @include responsive-size(margin-top, 100);
        @include responsive-size(width, 784);
        @include responsive-size(height, 665);
        .hp-mainframe {
          &, svg {
            @include responsive-size(width, 784);
            @include responsive-size(height, 665);
          }
        }
        .hp-homePreviewAndBtn {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .hp-homeSlider {
            position: relative;
            display: flex;
            align-items: center;
            @include responsive-size(margin-top, 15);
            @include responsive-size(margin-bottom, 25);
            .hp-leftArrow,
            .hp-rightArrow {
              position: absolute;
              @include responsive-size(width, 29);
              @include responsive-size(height, 60);
            }
            .hp-leftArrow {
              transform: rotate(180deg);
              @include responsive-size(left, -45);
            }
            .hp-rightArrow {
              right: 0;
              @include responsive-size(right, -45);
            }
            .hp-sliderImages {
              overflow: hidden;
              display: flex;
              overflow-x: hidden;
              &, img {
                @include responsive-size(width, 652);
                @include responsive-size(height, 336);
              }
              img:not(:last-child) {
                @include responsive-size(margin-right, 10);
              }
            }
          }
          .mainBtn {
            font-family: 'Avenir';
            font-weight: 900;
            background-color: #B171C9;
            @include responsive-size(width, 429); 
            @include responsive-size(height, 58);
            @include responsive-size(font-size, 24);
          }
        }
      }
    }
  }
}

.ks-boxShadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}


.ks-form-group {
  position: relative;
  .password-wrapper {
    display: contents;
    .eye-icon-wrapper {
      right: 0;
      bottom: 0;
      cursor: pointer;
      position: absolute;
      @include responsive-size(height, 81);
      @include responsive-size(width, 81);
      display: flex;
      align-items: center;
      justify-content: center;
      .eye-icon {
        display: flex;
        color: #C3C3C3;
        font-size: responsiveSizeToV2(28);
        &.bi-eye::before,
        &.bi-eye-slash::before {
          line-height: 0;
        }
      }
    }
  }
}

.ks-form-group {
  display: flex;
  flex-direction: column;
  @include responsive-size(margin-bottom, 20);
  .ks-form-label,
  .form-label {
    font-family: 'Avenir';
    font-weight: 900;
    @include responsive-size(font-size, 22);
  }
  textarea,
  input {
    -webkit-appearance: none;
    outline-style: none;
    box-shadow: none;
    border: 1px solid #C3C3C3;
    @include responsive-size(height, 72);
    @include responsive-size(margin-top, 5); 
    @include responsive-size(padding-left, 31); 
    @include responsive-size(border-radius, 10);
    @include responsive-size(font-size, 20);
    &:focus {
      border: 1px solid #51AABC;
    }
  }
}

.ks-formBtn {
  .mainBtn {
    font-family: 'Avenir';
    @include responsive-size(font-size, 24);
  }
}

amplify-authenticator {
  --border-radius: 28px;
  --amplify-primary-color: #51AABC;
  amplify-button {
    background-color: #51AABC;
  }
  section.section {
    @include responsive-size(border-radius, 28);
  }
}

.ks-hoopow-logo {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  @include responsive-size(border-bottom-right-radius, 46);
  &, svg {
    @include responsive-size(width, 122);
    @include responsive-size(height, 85);
  }
}