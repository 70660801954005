@import '../../../common.scss';

.hp-blockedModal-popup__header.mobile-only {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir';
  font-size: responsiveSizeToV2(46);
  margin-top: responsiveSizeToV2(27);
  margin-bottom: responsiveSizeToV2(32.7);

  .hp-blockedModal-popup__header-icon {

    &,
    svg {
      width: auto;
      height: responsiveSizeToV2(252.33);
      min-width: responsiveSizeToV2(252.33);
    }
  }

  .hp-blockedModal-popup__header-text {
    margin-left: responsiveSizeToV2(44.5);
    width: responsiveSizeToV2(478);
  }

  .hp-blockedModal-popup__header-desc {
    font-size: responsiveSizeToV2(39);
  }
}