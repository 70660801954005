@import '../../common.scss';
.hp-subscribe{
  background-color: #5FB1C1;
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Inter';
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  .hp-closeBtn path {
    fill: white;
  }
}

button[disabled] {
  cursor: not-allowed !important;
}

.hp-subscribe-plans-faq {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  @include responsive-size(margin-top, 124);
}
