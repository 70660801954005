@import '../../common.scss';
.hp-eidgift {

  .hp-eidgift-plans-form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include responsive-size(padding-top, 25);
    @include responsive-size(padding-bottom, 35);
    @include media("<" + $min_mobile) {
      @include responsive-size-mobile(padding-top, 10);
      @include responsive-size-mobile(padding-bottom, 10);
    }
    &.ks-form-group {
      margin: 0;
      textarea,
      input { 
        text-align: center; 
        padding: 0; 
        font-weight: 300;
        font-family: 'Avenir';
        @include responsive-size(border-radius, 2);
        @include responsive-size(font-size, 37);
        @include responsive-size(width, 539);
        @include responsive-size(height, 99);
        @include media("<" + $min_mobile) {
          @include responsive-size-mobile(border-radius, 2);
          @include responsive-size-mobile(font-size, 18);
          @include responsive-size-mobile(width, 330);
          @include responsive-size-mobile(height, 41);
        }
      }
    }
    &:not(:first-child) {
      border-top: solid #DDDDDD;
      @include responsive-size(border-width, 2);
      @include responsive-size(padding-top, 35);
      @include media("<" + $min_mobile) {
        @include responsive-size-mobile(border-width, 2);
        @include responsive-size-mobile(padding-top, 10);
      }
    }
    @include media("<" + $min_mobile) {
      &:last-child {
        border-top: none;
        @include responsive-size-mobile(padding-top, 0);
      }
    }
    label {
      font-weight: 400;
      font-family: 'Avenir';
      @include responsive-size(font-size, 37);
      @include media("<" + $min_mobile) {
        @include responsive-size-mobile(font-size, 20);
      }
    }
    .hp-eidgift-plans-form-plans {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Fredoka One';
      color: white;
      @include responsive-size(margin-top, 15);
      @include media("<" + $min_mobile) {
        @include responsive-size-mobile(margin-top, 6);
      }
      .hp-eidgift-plans-form-plan {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7F77CA;
        opacity: 0.3;
        @include responsive-size(border-radius, 10);
        @include responsive-size(font-size, 38);
        @include responsive-size(width, 315);
        @include responsive-size(height, 72);
        @include media("<" + $min_mobile) {
          @include responsive-size-mobile(width, 170);
          @include responsive-size-mobile(height, 45);
          @include responsive-size-mobile(border-radius, 5);
          @include responsive-size-mobile(font-size, 18);
        }
        &.selected {
          opacity: 1;
        }
        &:not(:last-child) {
          @include responsive-size(margin-right, 14);
          @include media("<" + $min_mobile) { 
            @include responsive-size-mobile(margin-right, 8);
          }
        }
      }
    }
    .hp-eidgift-plans-form-quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive-size(margin-top, 19);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(margin-top, 6);
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #7F77CA;
        border: solid #7F77CA;
        font-family: 'Fredoka One';
        @include responsive-size(font-size, 56);
        @include responsive-size(border-radius, 18);
        @include responsive-size(border-width, 6);
        @include responsive-size(margin-left, 26);
        @include responsive-size(margin-right, 26);
        @include responsive-size(width, 140);
        @include responsive-size(height, 80);
        @include media("<" + $min_mobile) { 
          @include responsive-size-mobile(font-size, 30);
          @include responsive-size-mobile(border-radius, 10);
          @include responsive-size-mobile(border-width, 4);
          @include responsive-size-mobile(margin-left, 14);
          @include responsive-size-mobile(margin-right, 14);
          @include responsive-size-mobile(width, 73);
          @include responsive-size-mobile(height, 38);
        }
      }
      svg {
        @include responsive-size(width, 90);
        @include responsive-size(height, 90);
        @include media("<" + $min_mobile) { 
          @include responsive-size-mobile(width, 47);
          @include responsive-size-mobile(height, 47);
        }
      }
    }
    button.mainBtn {
      text-transform: uppercase;
      @include responsive-size(border-radius, 50);
      @include responsive-size(font-size, 38);
      @include responsive-size(width, 285);
      @include responsive-size(height, 69);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(border-radius, 50);
        @include responsive-size-mobile(font-size, 16);
        @include responsive-size-mobile(width, 126);
        @include responsive-size-mobile(height, 39);
      }
    }
  }
  .hp-eidgift-plans-faq {
    width: 100%;
    display: flex;
    justify-content: center;
    @include responsive-size(margin-top, 90);

    @include media("<" + $min_mobile) { 
      flex-direction: column;
      @include responsive-size(margin-top, 25);
    }
    .hp-eidgift-plans-form {
      background-color: white;
      @include responsive-size(margin-right, 30);
      @include responsive-size(border-radius, 26);
      @include responsive-size(width, 738);
      @include responsive-size(height, 748);
      @include media("<" + $min_mobile) { 
        width: 100%;
        margin-right: 0;
        border-radius: 0;
        @include responsive-size-mobile(height, 316);
      }
    }
    .hp-faq {
      @include responsive-size(width, 553);
      @include responsive-size(margin-top, 13);
      @include responsive-size(margin-left, 30);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(width, 361);
        @include responsive-size-mobile(margin-top, 10);
        @include responsive-size-mobile(margin-left, 27);
      }
      height: auto;
      z-index: 3;
      .hp-faqs-question-answer {
        @include media("<" + $min_mobile) { 
          @include responsive-size-mobile(margin-top, 10);
        }
        .hp-faqs-answer-text {
          @include media("<" + $min_mobile) { 
            padding-top: 0;
            @include responsive-size-mobile(font-size, 12);
          }
        }
        &.hp-faq-selected {
          .hp-faqs-answer {
            @include media("<" + $min_mobile) { 
              @include responsive-size-mobile(height, 105);
            }
          }
        }
        .hp-faqs-header {
          background-color: white;
          color: black;
          font-weight: 400;
          @include responsive-size(font-size, 27);
          @include responsive-size(height, 84);
          .hp-faqs-plus {
            @include media("<" + $min_mobile) { 
              @include responsive-size-mobile(width, 37);
              @include responsive-size-mobile(height, 37);
            }
            path {
              fill: #6776C3;
            }
          }
          @include media("<" + $min_mobile) { 
            @include responsive-size-mobile(font-size, 18);
            @include responsive-size-mobile(height, 50);
          }
        }
      }
    }
    .hp-eidgift-card-preview {
      bottom: 0;
      right: 0;
      z-index: 0;
      position: absolute;
      @include responsive-size(width, 664);
      @include responsive-size(height, 533);
      @include media("<" + $min_mobile) { 
        display: none;
      }
    }
  }
}