@import '../../common.scss';
.hp-contactus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-size: cover;
  background-image: url('../../assets/v2/contactus.jpg');
  background-color: rgba($color: #D0BF87, $alpha: 0.6);
  background-blend-mode: multiply;
  .hp-closeBtn {
    position: absolute;
    @include responsive-size(right, 68);
    @include responsive-size(width, 121.43);
    @include responsive-size(height, 121.43);
    @include responsive-size(top, 45);
  }
  .ks-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    width: responsiveSizeToV2(946);
    height: responsiveSizeToV2(888);
    padding-left: responsiveSizeToV2(31);
    padding-right: responsiveSizeToV2(31);
    border-radius: responsiveSizeToV2(42);
    .ks-form-title {
      text-align: center;
      font-size: responsiveSizeToV2(35);
      font-family: 'Fredoka One';
      margin-bottom: responsiveSizeToV2(37);
    }
    .ks-form-group { 
      width: 100%;
      .ks-form-label {
        font-size: responsiveSizeToV2(25);
      }
    }
    .ks-form-group-checkbox {
      display: flex;
      justify-content: center;
      height: responsiveSizeToV2(41);
      margin-bottom: responsiveSizeToV2(45);
      input { 
        width: responsiveSizeToV2(25);
        height: responsiveSizeToV2(25);
        margin-right: responsiveSizeToV2(10);
      }
      label {
        font-weight: 300;
        font-family: 'Avenir';
        cursor: pointer;
        font-size: responsiveSizeToV2(13);
      }
    }
    .ks-form-group-sameline {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ks-form-group {  width: 48% }
    }
    textarea,
    input {
      color: black;
      font-weight: 200;
      font-family: 'Avenir';
      background-color: #F2F2F2;
      border-color: #F2F2F2;
      font-size: responsiveSizeToV2(22);
    }
    textarea {
      resize: none;
      height: responsiveSizeToV2(276);
    }
    .ks-formBtn {
      text-align: center;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(22);
      .mainBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: responsiveSizeToV2(30);
        width: responsiveSizeToV2(274);
        height: responsiveSizeToV2(66);
        border-radius: responsiveSizeToV2(33);
        svg {
          width: responsiveSizeToV2(42);
          height: responsiveSizeToV2(29);
          margin-right: responsiveSizeToV2(14);
        }
      }
    }
  }
}