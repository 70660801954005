@import '../../common.scss';

.foulane {
  display: flex;
  width: responsiveSizeToV2(1821);
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  text-align: center;
  background-color: #000;
  font-family: "Baloo Bhaijaan 2", serif;

  a {
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: inherit;
  }

  &--navbar {
    position: sticky;
    width: 100%;
    background-color: #000;
    color: #fff;
    display: flex;
    height: responsiveSizeToV2(103);
    align-items: center;
    justify-content: space-between;

    .container-left {
      display: flex;
      padding-left: responsiveSizeToV2(40);
      cursor: pointer;

      .hoopow-logo {
        overflow: visible;
        width: responsiveSizeToV2(219);
      }
    }

    .container-right {
      display: flex;
      gap: responsiveSizeToV2(8);
      font-size: responsiveSizeToV2(25);
      font-weight: 600;
      align-items: center;
      position: relative;

      .text {
        cursor: pointer;
      }

      .burger {
        display: block;
        position: relative;
        background: #fff;
        height: responsiveSizeToV2(69);
        width: responsiveSizeToV2(67);
        border-radius: responsiveSizeToV2(15);
        margin-right: responsiveSizeToV2(17.7);
        margin-left: responsiveSizeToV2(17);
        cursor: pointer;
        z-index: 20;

        &.to-burger {
          .line {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transition-duration: 0.6s;
            transition-timing-function: ease-out;
            transition-property: transform, opacity;

            &.first-line {
              top: responsiveSizeToV2(21.6);
              transform: rotateZ(0);
            }

            &.second-line {
              transform: rotateZ(0);
              opacity: 1;

            }

            &.third-line {
              bottom: responsiveSizeToV2(21.9);
              transform: rotateZ(0);
            }
          }
        }

        &.to-X {
          .line {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transition-duration: 0.6s;
            transition-timing-function: ease-out;
            transition-property: transform, opacity;


            &.first-line {
              transform: rotateZ(45deg);
            }

            &.second-line {
              transform: rotateZ(90deg);
              opacity: 0;

            }

            &.third-line {
              transform: rotateZ(calc(90deg + 45deg));
            }
          }
        }


        .line {
          background: #51aabc;
          width: responsiveSizeToV2(36.14);
          height: responsiveSizeToV2(3.65);
          border-radius: responsiveSizeToV2(15);
        }

      }

      .burger-menu {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        background: #FFFFFF;
        width: responsiveSizeToV2(315);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        gap: responsiveSizeToV2(10);
        border-top-left-radius: responsiveSizeToV2(50);
        border-bottom-left-radius: responsiveSizeToV2(50);
        font-size: responsiveSizeToV2(34);
        font-weight: 600;
        transition-duration: 0.6s;
        transition-timing-function: ease-in;
        padding-left: responsiveSizeToV2(32);
        padding-top: responsiveSizeToV2(40);
        padding-bottom: responsiveSizeToV2(40);
        z-index: 10;

        &.menu-visible {
          transform: translateX(0px);
        }

        &.menu-hidden {
          transform: translateX(400px);
          ;
        }

        div {
          width: 100%;
          height: responsiveSizeToV2(58);
          cursor: pointer;
        }

        .link {
          color: black;

          &.current-link {
            color: #51aabc;
          }
        }
      }
    }
  }

  &--banner {
    width: 100%;
    background-color: #FBD17F;
    display: grid;
    justify-content: center;

    svg {
      height: responsiveSizeToV2(620);
      width: auto;
    }
  }

  &--links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: responsiveSizeToV2(180);
    padding-bottom: responsiveSizeToV2(21);
    gap: responsiveSizeToV2(100);
    font-weight: bold;
    font-size: responsiveSizeToV2(35);
    background-color: #c8e2df;
    background-image: url("../../assets//foulane/links-bg.svg");
    background-position-x: responsiveSizeToV2(-87.72);
    background-position-y: bottom;
    background-size: responsiveSizeToV2(1964.5);
    background-repeat: no-repeat;
    background-origin: border-box;
    border: none;

    .link {
      cursor: pointer;
      color: black;
      padding-top: responsiveSizeToV2(9);
      height: responsiveSizeToV2(150);

      &.to-about {
        svg {
          height: responsiveSizeToV2(80.32);
        }
      }

      &.to-novels {
        svg {
          height: responsiveSizeToV2(90.27);
        }
      }

      &.to-audios {
        svg {
          height: responsiveSizeToV2(94.7);
        }
      }

      &.to-games {
        svg {
          height: responsiveSizeToV2(91.2);
        }
      }

      .icon {
        height: responsiveSizeToV2(100);
        //padding-top: responsiveSizeToV2(10);
        display: grid;
        justify-content: center;

        svg {
          cursor: pointer;
          width: auto;
          margin: auto;
          max-height: 100%;
          max-width: 100%;
        }

      }

      .title {
        margin-top: responsiveSizeToV2(-6);
      }

      .underline {
        display: none;
      }

      &.current-link {
        color: #468BB2;
        position: relative;

        .underline {
          display: grid;
          justify-content: center;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          bottom: responsiveSizeToV2(-2);
          margin-left: auto;
          margin-right: auto;
          z-index: 10;

          img {
            width: responsiveSizeToV2(175.8);
            height: auto;
          }
        }
      }
    }
  }

  &--description {
    width: 100%;
    background-color: #c8e2df;
    height: responsiveSizeToV2(952);
    padding-top: responsiveSizeToV2(110);
    padding-left: responsiveSizeToV2(300);
    padding-bottom: responsiveSizeToV2(152);
    //bgiu
    display: flex;

    .text {
      display: flex;
      flex-direction: column;
      z-index: 10;
      justify-content: start;
      text-align: left;
      width: responsiveSizeToV2(737);
      font-family: "Itim", sans-serif;
      font-size: responsiveSizeToV2(39);

      .title {
        font-weight: 800;
        font-size: responsiveSizeToV2(70);
        line-height: responsiveSizeToV2(65);
      }

      .info {
        background-color: #000;
        color: #fff;
        width: responsiveSizeToV2(485);
        height: responsiveSizeToV2(147);
        margin-top: responsiveSizeToV2(25);
        margin-bottom: responsiveSizeToV2(32);
        padding-left: responsiveSizeToV2(39);
        padding-right: responsiveSizeToV2(39);
        font-weight: normal;
      }
    }

    .image {
      align-self: flex-end;

      svg {
        width: responsiveSizeToV2(730);
        height: auto;
      }
    }
  }

  &--characters {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: responsiveSizeToV2(1887);
    flex-direction: column;
    //background-color: #c8e2df;
    background-image: url("../../assets/foulane/characters-bg.svg"), linear-gradient(#c8e2df 0%,transparent 50%);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: responsiveSizeToV2(-87.72);
    background-size: responsiveSizeToV2(1964.5);

    .title {
      font-weight: 800;
      font-size: responsiveSizeToV2(57);
      line-height: responsiveSizeToV2(53);
      height: responsiveSizeToV2(116);
      width: responsiveSizeToV2(521.7);
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      align-self: center;
      text-align: center;
      margin-top: responsiveSizeToV2(55.1);
      margin-bottom: responsiveSizeToV2(12.1);
      background-image: url("../../assets/foulane/characters-title-bg.svg");
      background-position: center center;
      background-repeat: no-repeat;
    }

    .container {
      display: inline-grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: responsiveSizeToV2(21);
      row-gap: responsiveSizeToV2(0);
      align-items: start;
      justify-content: start;
      margin-left: responsiveSizeToV2(202);
      margin-right: responsiveSizeToV2(202);
      margin-bottom: responsiveSizeToV2(90);

      .character {
        align-self: center;
        justify-self: center;
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: responsiveSizeToV2(326);
        height: responsiveSizeToV2(498);

        &.first-line {
          height: responsiveSizeToV2(587);
        }

        .name {
          font-weight: 800;
          font-size: responsiveSizeToV2(39);
          height: responsiveSizeToV2(44);
        }

        .icon {
          height: responsiveSizeToV2(258);

          img {
            width: responsiveSizeToV2(248);
            height: auto;
            max-height: 100%;
          }
        }

        .description {
          font-weight: normal;
          font-size: responsiveSizeToV2(25);
          font-family: "Itim", sans-serif;
        }
      }
    }

    margin-bottom: responsiveSizeToV2(-56);
  }

  &--noredine {
    width: 100%;
    display: flex;
    height: responsiveSizeToV2(523);
    padding-left: responsiveSizeToV2(300);
    padding-top: responsiveSizeToV2(85);
    padding-bottom: responsiveSizeToV2(38);
    font-family: "Itim", sans-serif;
    font-weight: normal;
    font-size: responsiveSizeToV2(30);
    text-align: left;
    background-image: url("../../assets/foulane/noredine-bg.svg"), linear-gradient(transparent 0%,#d8cdc0 50%);
    background-position-x: responsiveSizeToV2(-87.4);
    background-position-y: top;
    background-size: responsiveSizeToV2(1964.5);
    background-repeat: no-repeat;
    
    .text {
      width: responsiveSizeToV2(771);

      .title {
        font-family: "Baloo Bhaijaan 2", sans-serif;
        font-weight: 800;
        font-size: responsiveSizeToV2(70);
        height: responsiveSizeToV2(120);
      }
    }

    .image {
      justify-self: end;
      align-self: flex-end;
      display: grid !important;
      justify-content: center;
      align-items: center;
      margin-left: responsiveSizeToV2(188);

      img {
        height: responsiveSizeToV2(421);
        width: auto;
      }
    }
  }

  &--footer {
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    color: #ededed;
    text-align: left;
    padding-left: responsiveSizeToV2(300);
    padding-top: responsiveSizeToV2(42);

    .upper-container {
      display: flex;
      font-family: 'Avenir', sans-serif;
      font-size: responsiveSizeToV2(25);
      line-height: responsiveSizeToV2(30);
      font-weight: 300;

      .title {
        svg {
          width: responsiveSizeToV2(315.34);
          height: auto
        }
      }

      .description {
        width: responsiveSizeToV2(500);
        height: responsiveSizeToV2(320);
        margin-right: responsiveSizeToV2(140);
      }

      .links {
        width: responsiveSizeToV2(200);
        margin-right: responsiveSizeToV2(125);

        .link {

          font-weight: 500;

          &.current-link {
            font-weight: 900;
          }
        }
      }

      .download {
        .icons {
          display: flex;
          gap: responsiveSizeToV2(22);
          margin-left: responsiveSizeToV2(18);
          margin-top: responsiveSizeToV2(15);

          svg {
            width: responsiveSizeToV2(102.5);
            height: responsiveSizeToV2(102.5);
            cursor: pointer;
          }
        }
      }
    }

    .line {
      height: responsiveSizeToV2(2);
      background: #ededed;
    }

    .lower-container {
      margin-top: responsiveSizeToV2(14.5);

      gap: responsiveSizeToV2(21);
      display: flex;
      font-size: responsiveSizeToV2(20);

      .copyright {
        font-weight: 900;

      }

      .legal-links {
        font-weight: 500;
        gap: responsiveSizeToV2(44);
        display: flex;
      }
    }
  }
}