@import '../../common.scss';

.hp-v3.hp-embedded-member-space {
  &.hp-layoutV2 {
    height: 100%;
    .ks-embedded-authentication {
      height: auto;
      .ks-loginV3 {
        height: auto;
        .ks-form-left {
          height: auto;
        }
      }
    }
  }
  .hp-embedded-member {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    font-family: 'Avenir';
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    .hp-embedded-member-header {
      display: flex;
      align-items: center;
      font-size: responsiveSizeToV2(23);
      margin-top: responsiveSizeToV2(15);
      svg {
        width: responsiveSizeToV2(49);
        height: responsiveSizeToV2(49);
        margin-right: responsiveSizeToV2(10);
      }
    }
    .hp-embedded-memberBilling {
      .mainBtn {
        width: auto;
        height: auto;
        color: #C3C3C3;
        font-weight: 700;
        font-family: 'Avenir';
        border: solid #C3C3C3;
        background-color: white;
        padding-top: responsiveSizeToV2(11);
        padding-bottom: responsiveSizeToV2(11);
        padding-left: responsiveSizeToV2(44);
        padding-right: responsiveSizeToV2(44);
        font-size: responsiveSizeToV2(15);
        border-width: responsiveSizeToV2(2);
      }
    }
    .hp-embedded-member-title {
      color: #808080;
      font-family: 'Avenir';
      border-bottom: 1px solid #808080;
      font-size: responsiveSizeToV2(5);
    }

    .hp-embedded-member-title,
    .hp-embedded-member-boxes {
      width: 92.5%;
    }
    .hp-embedded-member-boxes {
      display: flex;
      justify-content: space-between;
      margin-top: responsiveSizeToV2(40);
      .hp-embedded-memberDetails,
      .hp-embedded-memberBilling {
        height: auto;
        width: responsiveSizeToV2(560);
      }
      .hp-embedded-memberDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: responsiveSizeToV2(28);
        .hp-embedded-memberForm {
          width: 75%;
        }
      }
      .hp-embedded-memberBilling {
        font-family: 'Avenir';
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .hp-embedded-member-contract,
        .hp-embedded-member-cb,
        .hp-embedded-member-bills {
          width: 100%;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: responsiveSizeToV2(28);
        }
        .hp-embedded-member-contract {
          color: black;
          height: responsiveSizeToV2(274);
          font-size: responsiveSizeToV2(23);
          margin-bottom: responsiveSizeToV2(40);
          .hp-embedded-planLoader {
            &, span {
              height: responsiveSizeToV2(70);
              width: responsiveSizeToV2(70);
              border-width: responsiveSizeToV2(4);
            }
          }
          &.hp-embedded-free-period {
            background-color: #51AABC;
            span {
              color: white;
              font-weight: 700;
              font-family: 'Avenir';
              font-size: responsiveSizeToV2(29);
            }
            button.mainBtn {
              color: #51AABC;
              border: solid #C3C3C3;
              background-color: white;
              margin-top: responsiveSizeToV2(13);
              border-radius: responsiveSizeToV2(68);
              font-size: responsiveSizeToV2(27);
              border-width: responsiveSizeToV2(2);
              width: responsiveSizeToV2(138);
              height: responsiveSizeToV2(32);
            }
          }
          .hp-embedded-member-info-title {
            color: white;
            display: flex;
            font-family: 'Avenir';
            font-size: responsiveSizeToV2(21);
            align-items: center;
            justify-content: center;
            margin-bottom: responsiveSizeToV2(33);
            svg {
              width: responsiveSizeToV2(31);
              height: responsiveSizeToV2(36);
              margin-right: responsiveSizeToV2(6);
            }
          }
          .hp-embedded-member-info-desc {
            color: white;
            text-align: center;
            font-weight: 900;
            font-size: responsiveSizeToV2(18);
          }
          .hp-embedded-member-info-subdesc {
            color: white;
            text-align: center;
            font-weight: 400;
            font-size: responsiveSizeToV2(18);
          }
          .hp-embedded-member-contract-type {
            font-weight: 900;
            text-decoration: underline;
            font-size: responsiveSizeToV2(22);
            margin-bottom: responsiveSizeToV2(5);
          }
          .hp-embedded-member-contract-desc {
            width: 90%;
            font-weight: 700;
            font-size: responsiveSizeToV2(18);
            margin-bottom: responsiveSizeToV2(20);
            label {
              color: #51AABC;
            }
          }
        }
        .hp-embedded-member-cb {
          height: responsiveSizeToV2(235);
          svg {
            width: auto;
            height: responsiveSizeToV2(38);
            margin-bottom: responsiveSizeToV2(10);
          }
        }
        .hp-embedded-member-gift-panels {
          display: flex;
          justify-content: space-between;
          height: responsiveSizeToV2(200);
          .hp-embedded-member-gift-cards-area,
          .hp-embedded-member-gift-code {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: responsiveSizeToV2(321);
            border-radius: responsiveSizeToV2(28);
            .mainBtn {
              background-color: white;
              border: 2px solid #C3C3C3;
              text-transform: uppercase;
              width: responsiveSizeToV2(202);
              height: responsiveSizeToV2(47);
            }
          }
        
          .hp-embedded-member-gift-code {
            background-color: #9178CF;
            textarea {
              color: white;
              resize: none;
              font-weight: 900;
              background-color: black;
              text-transform: uppercase;
              text-align: center;
              font-family: 'Avenir';
              width: responsiveSizeToV2(238);
              height: responsiveSizeToV2(83);
              font-size: responsiveSizeToV2(25);
              border-radius: responsiveSizeToV2(7);
              margin-bottom: responsiveSizeToV2(13);
            }
            .mainBtn {
              color: #9178CF;
              padding-top: responsiveSizeToV2(9);
              &.OK {
                color: white;
                background-color: #51AABC;
              }
              &.Erreur {
                color: white;
                background-color: #FB636F;
              }
            }
          }
        
          .hp-embedded-member-gift-cards-area {
            background-color: #5876BF;
            position: relative;
            color: white;
            font-family: 'Avenir';
            font-weight: 700;
            font-size: responsiveSizeToV2(35);
            text-align: center;
            line-height: 1.1;
            label { 
              margin-top: responsiveSizeToV2(5);
            }
            .mainBtn {
              color: #5876BF;
              z-index: 1;
              margin-top: responsiveSizeToV2(20);
              padding-top: responsiveSizeToV2(8);
            }
            svg {
              position: absolute;
              right: 0;
              z-index: 0;
              bottom: responsiveSizeToV2(13);
              width: responsiveSizeToV2(83);
              height: responsiveSizeToV2(83);
            }
          }
        }
      }
    }
  }
}

button[disabled] {
  cursor: not-allowed !important;
}

.hpmbedded--member-bills-title,
.hp-embedded-member-cb-title,
.hp-embedded-member-cb-number,
.hp-embedded-member-contract-title {
  color: #808080;
  font-family: 'Avenir';
  font-size: responsiveSizeToV2(23);
  margin-bottom: responsiveSizeToV2(15);
}
.hp-embedded-member-cb-number {
  color: #C3C3C3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: responsiveSizeToV2(20);
  svg {
    margin-right: responsiveSizeToV2(8);
  }
}

.hp-embedded-member-plans-faq {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: responsiveSizeToV2(124);
}

.hp-embedded-member-cancel-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Avenir';
  background-color: white;
  width: responsiveSizeToV2(673);
  height: responsiveSizeToV2(200);
  margin-top: responsiveSizeToV2(280);
  border-radius: responsiveSizeToV2(28);
  font-size: responsiveSizeToV2(24);
  .hp-embedded-member-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: responsiveSizeToV2(20);
    .mainBtn {
      font-size: responsiveSizeToV2(19);
      &.hp-embedded-plan-cancel-okBtn,
      &.hp-embedded-plan-cancel-noBtn {
        color: #C3C3C3;
        font-weight: 700;
        font-family: 'Avenir';
        border: solid #C3C3C3;
        background-color: white;
        margin-left: responsiveSizeToV2(20);
        border-width: responsiveSizeToV2(2);
      }
      &.hp-embedded-plan-cancel-okBtn {
        color: white;
        margin-left: 0;
        border: solid #51AABC;
        background-color: #51AABC;
      }
    }
  }
}
