@import '../../common.scss';

.hp-plans {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Avenir';
  font-weight: 300;
  background-color: white;
  @include responsive-size(font-size, 23);
  @include responsive-size(padding, 25);
  @include responsive-size(width, 630.8);
  @include responsive-size(height, 552.56);
  .hp-plans-proposition {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    @include responsive-size(font-size, 20);
    .hp-plans-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      @include responsive-size(width, 185.87);
      @include responsive-size(height, 197.56);
      @include responsive-size(margin-top, 20);
      @include responsive-size(margin-bottom, 40);
      &.hp-plan-selected {
        color: white;
        background-color: #5EB0C0;
        @include responsive-size(height, 222.56);
      }
      &:not(:last-child) {
        @include responsive-size(margin-right, 10);
      }
      .hp-plans-currency-price {
        position: relative;
        display: flex;
        .hp-plans-price {
          font-weight: 400;
          text-align: center;
          @include responsive-size(font-size, 75);
        }
        .hp-plans-price-mention {
          position: absolute;
          font-weight: 700;
          width: 100%;
          @include responsive-size(bottom, -5);
          @include responsive-size(font-size, 20);
        }
        .hp-plans-currency {
          font-weight: 200;
          position: absolute;
          @include responsive-size(left, -19);
          @include responsive-size(margin-top, 13);
          @include responsive-size(margin-right, 7);
          @include responsive-size(font-size, 34);
        }
      }
    }
  }
  .hp-plans-planDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    border: 2px solid black;
    @include responsive-size(font-size, 20);
    @include responsive-size(width, 577.87);
    @include responsive-size(height, 188);
    .hp-plans-planDescription-text {
      display: flex;
      align-items: center;
      justify-content: center;
      @include responsive-size(height, 81);
    }
    svg {
      @include responsive-size(width, 425.26);
      @include responsive-size(height, 35.1);
    }
    .mainBtn {
      background-color: black;
      font-weight: 900;
      font-family: 'Avenir';
      border-radius: 0;
      @include responsive-size(margin-top, 4);
      @include responsive-size(margin-bottom, 13);
      @include responsive-size(font-size, 20);
      @include responsive-size(width, 221);
      @include responsive-size(height, 49);
    }
  }
}