@import '../../../common.scss';

.hp-cookies {
  left: 0;
  bottom: 0;
  height: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: responsiveSizeToV2(660);
  border-radius: responsiveSizeToV2(29);
  transition: height 500ms ease;
  &.show {
    height: responsiveSizeToV2(479);
  }
  &__title {
    line-height: 1;
    text-align: center;
    font-family: 'Fredoka One';
    margin-top: responsiveSizeToV2(20);
    font-size: responsiveSizeToV2(28);
    width: auto;
    height: responsiveSizeToV2(78);
  }
  &__details {
    display: flex;
    box-sizing: content-box;
    align-items: center;
    padding-left: responsiveSizeToV2(27);
    padding-right: responsiveSizeToV2(27);
    div {
      flex: 1;
      line-height: 1;
      display: flex;
      flex-direction: column;
      p {    
        margin: 0;
        line-height: 1.35;
        font-family: 'Avenir';
        font-size: responsiveSizeToV2(18);
      }
      .mainBtn {
        line-height: 1;
        font-weight: 700;
        font-family: 'Avenir';
        background-color: #E89740;
        font-size: responsiveSizeToV2(26);
        width: responsiveSizeToV2(282);
        height: responsiveSizeToV2(72);
        margin-top: responsiveSizeToV2(10);
        border-radius: responsiveSizeToV2(11);
      }
      a {
        color: black;
        font-family: 'Avenir';
        text-decoration: underline;
        font-size: responsiveSizeToV2(16);
        margin-top: responsiveSizeToV2(10);
      }
    }
    svg {
      width: responsiveSizeToV2(292);
      height: responsiveSizeToV2(301);
      margin-right: responsiveSizeToV2(10);
    }
  }
}