@import '../../common.scss';

body.embeddedBody {
  background-color: transparent;
  overflow: hidden !important;

  .ks-embedded {
    width: 100%;

    &.mobile-only {
      height: 100%;
    }

    .hp-topBanner {
      display: none;
    }

    &.hp-v3 .ks-content {
      background-color: transparent;
      width: 100%;
      display: flex;
      border-radius: 0;
      justify-content: center;
      align-items: start; //center;
      margin: 0;

      .ks-loginV3 {
        box-shadow: none;
      }

      .hp-giftspace,
      .hp-eidgift {
        width: 100%;
      }
    }
  }

  .ks-form-group {
    .password-wrapper {
      .eye-icon-wrapper {
        width: responsiveSizeToV2(68);
        height: responsiveSizeToV2(68);
      }
    }
  }
}