@import '../../common.scss';

.ks-embedded {
  .hp-giftspace-apply-coupon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .hp-giftspace-apply-coupon-frame {
      display: flex;
      align-items: center;
      background-color: white;
      width: responsiveSizeToV2(800);
      height: responsiveSizeToV2(400);
      font-size: responsiveSizeToV2(32);
      margin-top: responsiveSizeToV2(200);
      border-radius: responsiveSizeToV2(26);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      svg {
        margin-left: responsiveSizeToV2(-20);
      }
      .hp-giftspace-apply-coupon-field {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: responsiveSizeToV2(-40);
        width: 60%;
        span {
          color: black;    
          font-weight: bold;
          font-family: 'Pauline';
          font-size: responsiveSizeToV2(50);
        }
        input {
          width: 100%;
          border: none;
          text-align: center;
          background-color: #E6E6E6;
          height: responsiveSizeToV2(66);
          font-size: responsiveSizeToV2(50);
          margin-top: responsiveSizeToV2(10);
          margin-bottom: responsiveSizeToV2(30);
          border-radius: responsiveSizeToV2(10);
        }
        button {
          font-size: responsiveSizeToV2(30);
          width: responsiveSizeToV2(200);
          height: responsiveSizeToV2(60);
        }
      }
    }
  }
}