@import '../../common.scss';

@function responsiveSizeOnlyWidth($sizeInPixel) {
  $unit: vw;
  $result: $sizeInPixel / 1920 * 1 * 100;
  @return $result + $unit;
}

body.hp-body-homepage-notconnected {
  overflow-y: auto;
  position: relative;
  svg.hp-cgu-text {
    cursor: auto;
    -webkit-user-select: auto; /* Safari */        
    -moz-user-select: auto; /* Firefox */
    -ms-user-select: auto; /* IE10+/Edge */
    user-select: auto; /* Standard */
  }
  .ks-auth-topBanner.hp-topBanner-cgu {
    position: fixed;
    &, svg {
      height: responsiveSizeOnlyWidth(96);
    }
    svg {
      margin-left: responsiveSizeOnlyWidth(42);
      width: responsiveSizeOnlyWidth(801.18);
    }
    background-color: black;
  }
  &.hp-body-homepage-notconnected-cgu {
    .ks-homepage-notconnected-topBtn {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      position: fixed;
      top: 0;
      right: 0;
      width: responsiveSizeOnlyWidth(545);
      height: responsiveSizeOnlyWidth(96);
      border-bottom-left-radius: responsiveSizeOnlyWidth(68);
      .mainBtn {
        font-weight: 700;
        font-family: 'Avenir';
        font-size: responsiveSizeOnlyWidth(22);
        width: responsiveSizeOnlyWidth(221);
        height: responsiveSizeOnlyWidth(49);
        &:first-child {
          margin-right: responsiveSizeOnlyWidth(25);
        }
        &.hp-homepage-connectionBtn {
          background-color: black;
          border: responsiveSizeOnlyWidth(2) solid white;
        }
      }
    }
  }
}

.hp-cgu {
  background-color: white;
  padding: responsiveSizeOnlyWidth(120);
  overflow-y: auto;
  .hp-closeBtn { display: none; }
  @at-root .hp-cgu-connected {
    .hp-cgu {
      padding: 8%;
      height: calc(var(--mainContentHeight) - 16%);
      .ks-auth-topBanner {display: none;}
      .ks-homepage-notconnected-topBtn {display: none;}
      .hp-back {display: none;}
      .hp-closeBtn {
        display: block;
        position: absolute;
        width: responsiveSizeOnlyWidth(125);
        height: responsiveSizeOnlyWidth(125);
        top: responsiveSizeOnlyWidth(30);
        right: responsiveSizeOnlyWidth(30);
      }
    }
  }
  .hp-back {
    display: flex;
    align-items: center;
    margin-top: responsiveSizeOnlyWidth(50);
    color: #51AABC;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: responsiveSizeOnlyWidth(32);
    margin-bottom: responsiveSizeOnlyWidth(10);
    svg {
      margin-right: responsiveSizeOnlyWidth(10);
      width: responsiveSizeOnlyWidth(50);
      height: responsiveSizeOnlyWidth(50);
    }
  }
  svg.hp-cgu-text {
    width: 100%;
    height: auto;
  }
}