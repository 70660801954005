@import '../../common.scss';



.hp-giftspace-mainlayout {
  .ks-content {
    @include media("<" + $min_mobile) { 
      margin: 0;
      height: 88vh;
      width: 100vw;
    }
  }
  .hp-giftspace{
    .hp-eidgift-plans-faq {
      @include responsive-size(margin-top, 46);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(margin-top, 19);
      }
    }
    .hp-eidgift-card-wrapper {
      .main-slider {
        @include responsive-size(margin-top, 42);
        @include media("<" + $min_mobile) { 
          @include responsive-size-mobile(margin-top, 150);
        }
      }
    }
    .hp-giftspace-tabs {
      display: flex;
      color: white;
      font-family: 'Avenir';
      font-weight: 900;
      @include responsive-size(margin-top, 16);
      @include responsive-size(font-size, 28);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(margin-top, 8);
        @include responsive-size-mobile(font-size, 13);
      }
      .hp-giftspace-tab-item {
        opacity: 0.3;
        &:first-child {
          @include responsive-size(margin-right, 33);
          @include media("<" + $min_mobile) { 
            @include responsive-size-mobile(margin-right, 10);
          }
        }
        &:last-child {
          @include responsive-size(margin-left, 33);
          @include media("<" + $min_mobile) { 
            @include responsive-size-mobile(margin-left, 10);
          }
        }
        &.selected {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}