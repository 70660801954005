@import "../../common.scss";

.hp-layoutV2 {
  .hp-homepageV2 {
    .hp-appNews,
    .hp-appCards,
    .hp-appSoon-title {
      margin-left: responsiveSizeToV2(18);
    }
    .hp-appNews-title{
      display: flex;
      align-items: center;
      &::after {
        content: '';
        width: responsiveSizeToV2(1130);
        margin-left: responsiveSizeToV2(52);
        height: 1px;
        background-color: #C3C3C3;
      }
    }
    .hp-appNews,
    .hp-appCards,
    .hp-appSoon {
      font-family: 'Avenir';
      &-frame {
        display: flex;
      }
      &-title {
        font-size: responsiveSizeToV2(19);
        font-family: 'Avenir';
        font-weight: 700;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        &::after {
          content: '';
          height: responsiveSizeToV2(1);
          background-color: #C3C3C3;
          margin-left: responsiveSizeToV2(12);
        }
      }
    }
    .hp-appNews {
      margin-top: responsiveSizeToV2(21);
      height: responsiveSizeToV2(374);
      &-title
      {
        position: relative;
        margin-bottom: responsiveSizeToV2(10);
        .hp-limitedPlan-duration {
          right: 0;
          color: white;
          background-color: #C96C5D;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          span { 
            text-transform: lowercase;
          }
          a {
            color: white;
            b { font-weight: 900; }
            text-decoration: none;
          }
          height: responsiveSizeToV2(40);
          padding: 0 responsiveSizeToV2(40);
          border-radius: responsiveSizeToV2(20);
          margin-right: responsiveSizeToV2(13);
        }
        &::after {
          width: responsiveSizeToV2(1200);
        }
      }
      .hp-appNews-frame {
        width: 100%;
        height: responsiveSizeToV2(342);
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        .hp-appNews-line {
          height: responsiveSizeToV2(302);
          min-width: responsiveSizeToV2(302);
          margin-top: responsiveSizeToV2(10);
          position: relative;
          box-shadow: 0 responsiveSizeToV2(3) responsiveSizeToV2(6) rgba(0, 0, 0, 0.16);
          border-radius: responsiveSizeToV2(22);
          display: inline-table;
          &:not(:first-child) {
            margin-left: responsiveSizeToV2(30);
          }
          &:last-child {
            margin-right: responsiveSizeToV2(18);
          }
          img {
            height: responsiveSizeToV2(302);
            min-width: responsiveSizeToV2(302);
            border-radius: responsiveSizeToV2(22);
          }
          .hp-appNews-line-date {
            position: absolute;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: black;
            font-weight: 700;
            text-transform: uppercase;
            font-size: responsiveSizeToV2(18);
            top: responsiveSizeToV2(10);
            right: responsiveSizeToV2(10);
            white-space: nowrap;
            padding-left: responsiveSizeToV2(10);
            padding-right: responsiveSizeToV2(10);
            min-width: responsiveSizeToV2(100);
            height: responsiveSizeToV2(35);
            background-color: white;
            border-radius: responsiveSizeToV2(22);
          }
          .hp-appNews-line-frame {
            display: flex;
            position: absolute;
            bottom: 0;
            align-items: center;
            background-color: white;
            width: 100%;
            border-bottom-left-radius: responsiveSizeToV2(22);
            border-bottom-right-radius: responsiveSizeToV2(22);
            overflow: hidden;
            font-size: responsiveSizeToV2(17);
            font-family: 'Avenir';
            line-height: 1.2;
            text-decoration: none;
            color: black;
            .hp-appNews-line-title {
              font-weight: 900;
              text-transform: uppercase;
            }
            svg {
              margin-right: responsiveSizeToV2(13);
              height: responsiveSizeToV2(55);
              width: auto;
            }
          }
        }
      }
    }

    .hp-appCards {
      height: responsiveSizeToV2(200);
      &-title {
        margin-top: responsiveSizeToV2(10);
        &::after {
          width: responsiveSizeToV2(1195);
        }
      }
      .hp-appCards-frame {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        margin-top: responsiveSizeToV2(10);
        &, .hp-appCard, svg {
          height: responsiveSizeToV2(127);
          width: auto;
        }
        .hp-appCard {
          margin-bottom: 0;
          &:not(:first-child) {
            margin-left: responsiveSizeToV2(23);
          }
        }
      }
    }

    .hp-appSoon {
      height: responsiveSizeToV2(442);
      &-title::after {
        width: responsiveSizeToV2(1240);
      }
      .hp-appSoon-frame {
        margin-top: responsiveSizeToV2(9);
        width: 100%;
        display: flex;
        justify-content: space-between;
        svg {
          height: responsiveSizeToV2(400);
          width: auto;
        }
      }
    }
  }
}
