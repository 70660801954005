@import '../../../common.scss';

.ks-embedded {
  .hp-myorders-empty {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 85%;
    height: 100%;
    .hp-myorders-empty-rect {
      background-color: #DDDDDD;
      width: responsiveSizeToV2(170);
      height: responsiveSizeToV2(170);
      border-radius: responsiveSizeToV2(12);
    }
    .hp-myorders-empty-orderdate,
    .hp-myorders-empty-ordertype {
      width: 100%;
      border: 1px solid #DDDDDD;
      height: responsiveSizeToV2(55);
      border-radius: responsiveSizeToV2(12);
    }
  }
}